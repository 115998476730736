import z from 'zod'

import { MAX_ATTACHMENTS } from '@constants/tool'

import { GetEntitySchema } from './network'
import { WarehouseToolBaseSchema } from './warehouseTool'

// User when get all tool
export const ToolBaseSchema = z.object({
  uid: z.string(),
  name: z
    .string()
    .min(3, 'Nombre debe tener al menos 3 caracteres')
    .max(50, 'Nombre debe tener como máximo 50 caracteres')
    .trim(),
  description: z.string().max(100, 'Descripción debe tener como máximo 100 caracteres').trim(),
  alertStock: z.number(),
  value: z.number().positive('Valor debe ser mayor a 0'),
  deleted: z.boolean().optional(),
  attachments: z
    .array(z.string())
    .max(MAX_ATTACHMENTS, `Máximo ${MAX_ATTACHMENTS} archivos adjuntos`),
  updatedAt: z.number(),
  createdAt: z.number(),
})

export type ToolBaseType = z.infer<typeof ToolBaseSchema>

// Internal use
export const ToolFirebaseSchema = ToolBaseSchema

export type ToolFirebaseType = z.infer<typeof ToolFirebaseSchema>

// User when get a tool by id AND specific warehouse
export const ToolWarehouseSchema = ToolBaseSchema.extend({
  stock: z.number().positive('Stock debe ser mayor a 0'),
  warehouseTool: z.lazy(() => WarehouseToolBaseSchema),
})

export type ToolWarehouseType = z.infer<typeof ToolWarehouseSchema>

//
// CRUD OPERATIONS
//
//Create (from warehouse)
export const CreateToolBaseSchema = z.object({
  //warehouseID: z.string(),
  tool: ToolBaseSchema.omit({
    createdAt: true,
    updatedAt: true,
    deleted: true,
    uid: true,
  }),
})

export type CreateToolBaseType = z.infer<typeof CreateToolBaseSchema>

//Update
export const UpdateToolBaseSchema = z.object({
  //warehouseID: z.string(),
  tool: ToolBaseSchema.partial().extend({
    uid: z.string(),
  }),
})

export type UpdateToolBaseType = z.infer<typeof UpdateToolBaseSchema>

//Delete
export const DeleteToolSchema = z.object({
  //warehouseID: z.string(),
  toolID: z.string(),
})

export type DeleteToolType = z.infer<typeof DeleteToolSchema>

export const DeleteToolsSchema = z.object({
  //warehouseID: z.string(),
  toolIDs: z.array(z.string()),
})

export type DeleteToolsType = z.infer<typeof DeleteToolsSchema>

//
// GET OPERATIONS
//
//Get all material from all warehouses
export const GetToolsSchema = GetEntitySchema

export type GetToolsType = z.infer<typeof GetToolsSchema>

// Get materials by warehouse
export const GetToolsWarehouseSchema = GetEntitySchema.extend({
  warehouseToolID: z.string(),
})

export type GetToolsWarehouseType = z.infer<typeof GetToolsWarehouseSchema>

//Get material by id AND specific warehouse
export const GetToolWarehouseSchema = z.object({
  warehouseToolID: z.string(),
  toolID: z.string(),
})

export type GetToolWarehouseType = z.infer<typeof GetToolWarehouseSchema>
