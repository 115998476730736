import React from 'react'
import { useForm } from '@mantine/form'
import { useListState } from '@mantine/hooks'
import { notifications } from '@mantine/notifications'
import { zodResolver } from 'mantine-form-zod-resolver'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Button, Container, Fieldset, Table, Text } from '@mantine/core'

import {
  OrderPurchaseType,
  UpdateOrderPurchaseType,
  CreateOrderPurchaseType,
  CreateOrderPurchaseSchema,
} from '@customTypes/orderPurchase'
import { ModeType } from '@customTypes/page'
import { RawMaterialBaseType } from '@customTypes/rawMaterial'

import DataRepo from '@api/datasource/data'

import QueryKeys from '@constants/queryKeys'
import { RoutesApp } from '@constants/routes'
import { LIMIT_SELECT_SEARCH } from '@constants/app'

import { useIsMobile, useTableMinWidth } from '@hooks/mobile'

import { ErrorService } from '@utils/error'
import { buildSelectOptions, filterSelect } from '@utils/form'
import { isLoadingMutation, isLoadingOrRefetchQuery } from '@utils/network'

import Input from '@components/shared/input'
import LoaderText from '@components/shared/loader'
import ConfirmationModal from '@components/shared/confirmationModal'
import TableCustom, { GenericColumnType } from '@components/shared/table'

const INITIAL: CreateOrderPurchaseType = {
  name: '',
  description: '',
  rawMaterials: [],
}

type SourceDataType = Record<string, OrderPurchaseType['rawMaterials'][0]>

const OrderPurchaseForm = () => {
  const navigate = useNavigate()

  const { uid } = useParams<{ uid: string }>()

  const mode: ModeType = uid ? 'edit' : 'create'

  const isMobile = useIsMobile()

  const minWidth = useTableMinWidth(isMobile)

  const [confirmExit, setConfirmExit] = React.useState(false)

  const [listItem, handlers] = useListState<string>([])

  const [itemSearch, setItemSearch] = React.useState<string | null>(null)

  const orderPurchaseQuery = useQuery<OrderPurchaseType, ErrorService, OrderPurchaseType, string[]>(
    {
      enabled: mode === 'edit',
      queryKey: [QueryKeys.GET_ORDER_PURCHASE_KEY, String(uid)],
      queryFn: async ({ queryKey }) => {
        const response = await DataRepo.documentEntitiesService.getOrderPurchaseById(queryKey[1])

        return response
      },
    },
  )

  const formOrderPurchase = useForm<CreateOrderPurchaseType>({
    initialValues: INITIAL,
    validate: zodResolver(CreateOrderPurchaseSchema),
  })

  const { rawMaterials: itemsForm } = formOrderPurchase.getValues()

  const rawMaterialsQuery = useQuery<RawMaterialBaseType[], ErrorService, RawMaterialBaseType[]>({
    initialData: [],
    queryKey: [QueryKeys.GET_MATERIALS_KEY],
    queryFn: async () => {
      const response = await DataRepo.constructionEntitiesService.getRawMaterials({})

      return response
    },
  })

  // const totalsOrderPurchaseQuery = useQuery<
  //   TotalsOrderType,
  //   ErrorService,
  //   TotalsOrderType,
  //   [string, CreateOrderPurchaseType['rawMaterials']]
  // >({
  //   initialData: {
  //     total: 0,
  //   },
  //   refetchOnMount: true,
  //   queryKey: [QueryKeys.GET_PROFORMA_TOTAL_KEY, itemsForm],
  //   queryFn: async ({ queryKey }) => {
  //     const [, rawMaterials] = queryKey

  //     return getTotalsOrderPurchase({
  //       rawMaterials,
  //     })
  //   },
  // })

  const orderPurchaseCreateMutation = useMutation<
    OrderPurchaseType,
    ErrorService,
    CreateOrderPurchaseType
  >({
    mutationFn: async (values) => {
      const response = await DataRepo.documentEntitiesService.createOrderPurchase(values)

      return response
    },
    onSettled: (_, error) => {
      if (error) {
        return notifications.show({
          color: 'red',
          title: 'Error',
          message: error.message ?? 'Error al crear la orden de compra',
        })
      }

      notifications.show({
        color: 'green',
        title: 'Éxito',
        message: 'Orden de compra creada correctamente',
      })

      navigate(RoutesApp.ORDER_PURCHASES)
    },
  })

  const sourceDataQuery = useQuery<
    SourceDataType,
    ErrorService,
    SourceDataType,
    [string, RawMaterialBaseType[], OrderPurchaseType['rawMaterials']]
  >({
    gcTime: 0,
    staleTime: 0,
    initialData: {},
    refetchOnMount: true,
    queryKey: [QueryKeys.GET_SOURCE_DATA_ORDER_PURCHASE_KEY, rawMaterialsQuery.data, itemsForm],
    queryFn: async ({ queryKey }) => {
      const [, rawMaterials, existingItems] = queryKey

      const existingIDs = existingItems.map(({ uid }) => uid)

      const tempItems: Record<string, OrderPurchaseType['rawMaterials'][0]> = {}

      if (rawMaterials.length) {
        for (const rawMaterial of rawMaterialsQuery.data) {
          tempItems[rawMaterial.uid] = {
            ...rawMaterial,
            quantity: 1,
            disabled: existingIDs.includes(rawMaterial.uid),
          }
        }
      }

      return tempItems
    },
  })

  const orderPurchaseUpdateMutation = useMutation<void, ErrorService, UpdateOrderPurchaseType>({
    mutationFn: async (values) => {
      const response = await DataRepo.documentEntitiesService.updateOrderPurchase(values)

      return response
    },
    onSettled: (_, error) => {
      if (error) {
        return notifications.show({
          color: 'red',
          title: 'Error',
          message: error.message ?? 'Error al actualizar la orden de compra',
        })
      }

      notifications.show({
        color: 'green',
        title: 'Éxito',
        message: 'Orden de compra actualizada correctamente',
      })

      navigate(RoutesApp.ORDER_PURCHASES)
    },
  })

  React.useEffect(() => {
    if (mode === 'edit' && orderPurchaseQuery.data) {
      formOrderPurchase.setValues(orderPurchaseQuery.data)
      formOrderPurchase.resetDirty()
    }

    return () => {
      formOrderPurchase.setValues(INITIAL)
      formOrderPurchase.resetDirty()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderPurchaseQuery.data])

  const isLoading = isLoadingOrRefetchQuery(orderPurchaseQuery)

  const isLoadingForm = isLoadingMutation(orderPurchaseCreateMutation, orderPurchaseUpdateMutation)

  const isLoadingItems = isLoadingOrRefetchQuery(rawMaterialsQuery)

  const isLoadingSourceData = isLoadingOrRefetchQuery(sourceDataQuery)

  const rawMaterialSearchInputProps = formOrderPurchase.getInputProps('rawMaterials')

  return (
    <React.Fragment>
      {!isLoading && (
        <Container
          className="cd-mt-[1rem] cd-mb-[2rem] md:cd-mt-[2rem] md:cd-pb-[4rem]"
          fluid={isMobile}
          size={isMobile ? '100%' : 'xl'}
        >
          <form
            onSubmit={formOrderPurchase.onSubmit((values) => {
              if (mode === 'edit' && orderPurchaseQuery.data) {
                orderPurchaseUpdateMutation.mutate({
                  ...values,
                  uid: orderPurchaseQuery.data.uid,
                })
              } else {
                orderPurchaseCreateMutation.mutate(values)
              }
            })}
          >
            <div className="cd-flex cd-flex-col cd-gap-y-[0.5rem] md:cd-justify-between md:cd-flex-row">
              <Text className="cd-text-lg">
                {mode === 'edit' ? 'Editar orden de compra' : 'Crear orden de compra'}
              </Text>
              <div className="cd-flex cd-flex-col cd-gap-[0.5rem] md:cd-justify-between md:cd-flex-row">
                <Button
                  color="gray"
                  onClick={() => {
                    if (formOrderPurchase.isDirty()) {
                      setConfirmExit(true)
                    } else {
                      navigate(-1)
                    }
                  }}
                >
                  Volver
                </Button>
                <Button
                  color="green"
                  disabled={!formOrderPurchase.isDirty()}
                  loaderProps={{ type: 'dots' }}
                  loading={isLoadingForm}
                  type="submit"
                >
                  {`${mode === 'edit' ? 'Actualizar' : 'Crear'} orden de compra`}
                </Button>
              </div>
            </div>
            <div className="cd-flex cd-flex-col cd-gap-y-[0.5rem] cd-mt-[1rem]">
              <Fieldset className="cd-flex cd-flex-col cd-gap-y-[0.5rem]" legend="Orden">
                <Input
                  className="cd-basis-[100%]"
                  label="Nombre"
                  placeholder="Ingrese el nombre de la orden"
                  typeInput="text"
                  {...formOrderPurchase.getInputProps('name')}
                  onBlur={() => formOrderPurchase.validateField('name')}
                />

                <Input
                  autosize
                  className="cd-basis-[100%]"
                  label="Descripción"
                  maxRows={6}
                  minRows={3}
                  placeholder="Ingrese la descripción de la orden"
                  typeInput="textarea"
                  {...formOrderPurchase.getInputProps('description')}
                  onBlur={() => formOrderPurchase.validateField('description')}
                />
              </Fieldset>
              <Fieldset legend="Materiales">
                <TableCustom<OrderPurchaseType['rawMaterials'][0]>
                  alwaysHeader
                  hidePagination
                  columns={buildColumns()}
                  data={itemsForm}
                  extraRows={buildExtraRows()}
                  keyId="uid"
                  limitPage={1000}
                  minWidth={minWidth}
                  noDataMessage="Agregue items a la orden de compra"
                  selectedRows={listItem}
                  onChangeSelected={handlers}
                  onDelete={(uids) => {
                    formOrderPurchase.setFieldValue(
                      'items',
                      itemsForm.filter((item) => !uids.includes(item.uid)),
                      { forceUpdate: true },
                    )
                    handlers.setState([])
                  }}
                />
              </Fieldset>
            </div>
          </form>
        </Container>
      )}
      {isLoading && (
        <LoaderText>
          <Text c="dimmed" className="cd-text-base">
            Cargando orden de compra
          </Text>
        </LoaderText>
      )}
      <ConfirmationModal
        cancelColor="gray"
        cancelText="Cancelar"
        confirmColor="red"
        confirmText="Descartar"
        opened={confirmExit}
        title="Descartar cambios"
        onCancel={() => setConfirmExit(false)}
        onConfirm={() => {
          setConfirmExit(false)
          navigate(-1)
        }}
      >
        <Text className="cd-text-base">
          Has realizado cambios en el formulario, ¿Deseas cerrarlo y descartar los cambios?
        </Text>
      </ConfirmationModal>
    </React.Fragment>
  )

  function buildExtraRows() {
    return (
      <React.Fragment key="extra-fragment">
        <Table.Tr key="search-row">
          <Table.Td colSpan={6}>
            <Input
              clearable
              searchable
              data={buildSelectOptions({
                data: Object.values(sourceDataQuery.data ?? {}),
                value: 'uid',
                label: 'name',
                disabledKey: 'disabled',
              })}
              disabled={isLoadingSourceData || isLoadingItems}
              filter={(input) =>
                filterSelect({
                  limit: LIMIT_SELECT_SEARCH,
                  search: input.search,
                  options: input.options,
                })
              }
              loading={isLoadingSourceData || isLoadingItems}
              nothingFoundMessage="No se encontraron item"
              placeholder="Buscar item por nombre"
              readOnly={isLoadingSourceData}
              typeInput="select"
              {...rawMaterialSearchInputProps}
              value={itemSearch}
              onChange={(value) => handleSearchChange(value)}
            />
          </Table.Td>
        </Table.Tr>
        {/* <Table.Tr key="total-row">
          <Table.Td colSpan={4} />
          <Table.Td className="cd-font-bold">Total</Table.Td>
          <Table.Td className="cd-font-bold cd-text-right">
            <NumberFormat value={totalsOrderPurchaseQuery.data?.total ?? 0} />
          </Table.Td>
        </Table.Tr> */}
      </React.Fragment>
    )
  }

  function handleSearchChange(value: string | null) {
    setItemSearch(value)

    if (!value) return

    const item = sourceDataQuery.data?.[value]

    if (!item) return

    formOrderPurchase.insertListItem('rawMaterials', { ...item })

    setItemSearch(null)
  }

  function buildColumns() {
    return [
      {
        key: 'name',
        label: 'Nombre',
        width: '35%',
        type: 'calc',
        defaultOnClick: true,
        render: ({ name }) => name,
      },
      {
        key: 'description',
        label: 'Descripción',
        width: '45%',
        type: 'calc',
        hideMenu: true,
        render: ({ description }) => description,
      },
      {
        key: 'quantity',
        label: 'Cantidad',
        width: '20%',
        type: 'number',
        inputProps: {
          min: 0,
          allowNegative: false,
          defaultValue: ({ quantity }) => quantity ?? 1,
          onChange: handleItemInput.bind(null, 'quantity'),
        },
      },
      // {
      //   key: 'value',
      //   label: 'P. Unitario',
      //   width: '15%',
      //   type: 'calc',
      //   align: 'right',
      //   render: ({ value }) => <NumberFormat value={value} />,
      // },
      // {
      //   key: 'total',
      //   label: 'Total',
      //   width: '15%',
      //   type: 'calc',
      //   align: 'right',
      //   render: ({ quantity, value }) => <NumberFormat prefix="$" value={quantity * value} />,
      // },
    ] as GenericColumnType<OrderPurchaseType['rawMaterials'][0]>[]
  }

  function handleItemInput(
    key: keyof OrderPurchaseType['rawMaterials'][0],
    value: number | string,
    uid: string,
  ) {
    const itemIdx = itemsForm.findIndex((item) => item.uid === uid)

    if (itemIdx === -1) return

    formOrderPurchase.setFieldValue(`rawMaterials.${itemIdx}.${key}`, value, { forceUpdate: true })
  }
}

export default OrderPurchaseForm
