import React from 'react'
import { useForm } from '@mantine/form'
import { Button, Fieldset, Modal, Text } from '@mantine/core'
import { notifications } from '@mantine/notifications'
import { zodResolver } from 'mantine-form-zod-resolver'
import { useMutation, useQuery } from '@tanstack/react-query'

import {
  ToolBaseType,
  CreateToolBaseType,
  UpdateToolBaseType,
  CreateToolBaseSchema,
} from '@customTypes/tool'
import { ModalProps } from '@customTypes/page'

import DataRepo from '@api/datasource/data'
import queryClient from '@api/datasource/query'

import QueryKeys from '@constants/queryKeys'
import { MAX_ATTACHMENTS } from '@constants/tool'

import { useIsMobile } from '@hooks/mobile'

import { ErrorService } from '@utils/error'
import { isLoadingMutation, isLoadingOrRefetchQuery } from '@utils/network'
import { buildSelectOptions, filterSelect } from '@utils/form'

import Input from '@components/shared/input'
import { Dropzone } from '@components/shared/dropzone'
import ConfirmationModal from '@components/shared/confirmationModal'
import { filterToolsAttachments } from '@utils/tool'
import { LIMIT_SELECT_SEARCH } from '@constants/app'

const INITIAL: CreateToolBaseType['tool'] = {
  name: '',
  description: '',
  value: 0,
  alertStock: 0,
  attachments: [],
}

const ToolForm = (props: ModalProps<ToolBaseType>) => {
  const { open, modal, data, size = 'lg', onSubmitted, onClose: outerOnClose } = props

  const isMobile = useIsMobile()

  const [confirmExit, setConfirmExit] = React.useState(false)

  const [selectedReuseRM, setSelectedReuseRM] = React.useState<ToolBaseType | null>(null)

  const [toolsNames, setToolsNames] = React.useState<string[]>([])

  const formTool = useForm<CreateToolBaseType['tool']>({
    initialValues: INITIAL,
    validate: zodResolver(CreateToolBaseSchema.shape.tool),
  })

  const toolsQuery = useQuery<ToolBaseType[], ErrorService>({
    initialData: [],
    queryKey: [QueryKeys.GET_TOOLS_KEY],
    queryFn: async () => {
      return await DataRepo.constructionEntitiesService.getTools({
        orderDir: 'asc',
      })
    },
  })

  const toolsAttachmentsQuery = useQuery<
    ToolBaseType[],
    ErrorService,
    ToolBaseType[],
    [string, ToolBaseType[]]
  >({
    initialData: [],
    enabled: Boolean(toolsQuery.data?.length),
    queryKey: [QueryKeys.GET_TOOLS_ATTACHMENTS_KEY, toolsQuery.data],
    queryFn: async ({ queryKey }) => {
      const tools = queryKey[1]

      const filteredTools = filterToolsAttachments(tools)

      return filteredTools
    },
  })

  const toolCreateMutation = useMutation<ToolBaseType, ErrorService, CreateToolBaseType>({
    mutationFn: async (data) => {
      const response = await DataRepo.constructionEntitiesService.createTool(data)

      await queryClient.invalidateQueries({
        predicate: (query) => [QueryKeys.GET_TOOLS_KEY].includes(query.queryKey[0] as string),
        refetchType: 'all',
      })

      onSubmitted?.(response)

      return response
    },
    onSettled: (_, error) => {
      if (error) {
        return notifications.show({
          color: 'red',
          title: 'Error',
          message: error.message ?? 'Error al crear la herramienta',
        })
      }

      notifications.show({
        color: 'green',
        title: 'Éxito',
        message: 'Herramienta creada correctamente',
      })

      onClose()
    },
  })

  const toolUpdateMutation = useMutation<void, ErrorService, UpdateToolBaseType>({
    mutationFn: async (data) => {
      const response = await DataRepo.constructionEntitiesService.updateTool(data)

      await queryClient.invalidateQueries({
        predicate: (query) => [QueryKeys.GET_TOOLS_KEY].includes(query.queryKey[0] as string),
        refetchType: 'all',
      })

      onSubmitted?.()

      return response
    },
    onSettled: (_, error) => {
      if (error) {
        return notifications.show({
          color: 'red',
          title: 'Error',
          message: error.message ?? 'Error al actualizar la herramienta',
        })
      }

      notifications.show({
        color: 'green',
        title: 'Éxito',
        message: 'Herramienta actualizada correctamente',
      })

      onClose?.()
    },
  })

  React.useEffect(() => {
    if (open && data) {
      formTool.setValues(data)
    } else if (!open) {
      setSelectedReuseRM(null)
      formTool.setValues(INITIAL)
    }
    formTool.resetDirty()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, data])

  React.useEffect(() => {
    if (!toolsQuery.data) return

    setToolsNames(toolsQuery.data.map((item) => item.name))
  }, [toolsQuery.data])

  const isLoading = isLoadingMutation(toolCreateMutation, toolUpdateMutation)

  const isLoadingAttachments = isLoadingOrRefetchQuery(toolsAttachmentsQuery, toolsQuery)

  const content = (
    <form
      onSubmit={formTool.onSubmit((values) => {
        if (data) {
          toolUpdateMutation.mutate({
            tool: {
              ...values,
              uid: data.uid,
            } as UpdateToolBaseType['tool'],
          })
        } else {
          toolCreateMutation.mutate({
            tool: values as CreateToolBaseType['tool'],
          })
        }
      })}
    >
      <div className="cd-flex cd-flex-col cd-gap-y-[1rem]">
        <Input
          label="Nombre"
          placeholder="Nombre"
          typeInput="text"
          {...formTool.getInputProps('name')}
          onBlur={() => {
            const isDuplicated = toolsNames.includes(formTool.values.name)

            if (isDuplicated) {
              formTool.setFieldError('name', 'El nombre de la herramienta ya existe')
              return
            }

            formTool.validateField('name')
          }}
          onChange={(e) => {
            formTool.clearFieldError('name')
            formTool.setFieldValue('name', e.target.value, { forceUpdate: true })
          }}
        />
        <Input
          label="Descripción"
          placeholder="Descripción"
          typeInput="text"
          {...formTool.getInputProps('description')}
          onBlur={() => formTool.validateField('description')}
        />

        <div className="cd-flex cd-flex-col cd-gap-y-[1rem] md:cd-flex-row md:cd-gap-x-[1rem]">
          <Input
            allowDecimal
            allowNegative={false}
            clampBehavior="strict"
            className="cd-basis-[100%] md:cd-basis-1/2"
            label="Valor"
            min={0}
            placeholder="Valor de la herramienta"
            prefix="$ "
            thousandSeparator=","
            typeInput="number"
            {...formTool.getInputProps('value')}
            onBlur={() => formTool.validateField('value')}
          />
          <Input
            allowDecimal
            clampBehavior="strict"
            className="cd-basis-[100%] md:cd-basis-1/2"
            label="Alerta de stock"
            placeholder="Cantidad mínima para alerta"
            thousandSeparator=","
            typeInput="number"
            {...formTool.getInputProps('alertStock')}
            onBlur={() => formTool.validateField('alertStock')}
          />
        </div>

        <Fieldset legend="Archivos adjuntos">
          <Input
            clearable
            searchable
            data={buildSelectOptions<ToolBaseType>({
              data: toolsAttachmentsQuery.data,
              value: 'uid',
              label: (item) =>
                `${item.name} (${item.attachments.length === 1 ? `${item.attachments.length} archivo` : `${item.attachments.length} archivos`})`,
            })}
            filter={(input) =>
              filterSelect({
                limit: LIMIT_SELECT_SEARCH,
                search: input.search,
                options: input.options,
              })
            }
            nothingFoundMessage={
              <Text c="dimmed" className="cd-text-sm">
                No se encontraron resultados
              </Text>
            }
            placeholder="Escribe para buscar herramienta existente"
            typeInput="select"
            value={selectedReuseRM?.uid ?? ''}
            onChange={(value) => {
              if (!value) {
                formTool.setFieldValue(
                  'attachments',
                  formTool.values.attachments.filter(
                    (item) => !selectedReuseRM?.attachments.includes(item),
                  ),
                  { forceUpdate: true },
                )

                setSelectedReuseRM(null)
                return
              }

              const tool = toolsAttachmentsQuery.data?.find((item) => item.uid === value)

              if (!tool) return

              const currentFiles = formTool.values.attachments ?? []

              const newFiles = [...currentFiles, ...tool.attachments]

              if (newFiles.length > MAX_ATTACHMENTS) {
                notifications.show({
                  color: 'yellow',
                  title: 'Advertencia',
                  message: `Se ha preservado solo los últimos ${MAX_ATTACHMENTS} archivos adjuntos`,
                })
              }

              formTool.setFieldValue('attachments', newFiles.slice(0, MAX_ATTACHMENTS), {
                forceUpdate: true,
              })

              setSelectedReuseRM(tool)
            }}
          />

          <Dropzone
            multiple
            className="cd-mt-[1rem]"
            disabled={isLoading || formTool.values.attachments.length === 4}
            files={formTool.values.attachments}
            maxFiles={MAX_ATTACHMENTS}
            onDrop={(files) => {
              if (files.length > MAX_ATTACHMENTS) {
                notifications.show({
                  color: 'yellow',
                  title: 'Advertencia',
                  message: `Se ha preservado solo los últimos ${MAX_ATTACHMENTS} archivos adjuntos`,
                })
              }

              formTool.setFieldValue('attachments', files.slice(0, MAX_ATTACHMENTS), {
                forceUpdate: true,
              })
            }}
          />
        </Fieldset>

        <Button
          className="cd-mt-[1rem]"
          disabled={!formTool.isValid() || isLoadingAttachments}
          loaderProps={{ type: 'dots' }}
          loading={isLoading}
          type="submit"
        >
          {data ? 'Guardar herramienta' : 'Crear herramienta'}
        </Button>
      </div>
    </form>
  )

  if (modal) {
    return (
      <Modal
        centered
        fullScreen={isMobile}
        opened={Boolean(open)}
        size={size}
        title={data ? 'Editar herramienta' : 'Agregar herramienta'}
        onClose={() => {
          if (formTool.isDirty()) {
            setConfirmExit(true)
          } else {
            onClose()
          }
        }}
      >
        {content}
        <ConfirmationModal
          cancelColor="gray"
          cancelText="Cancelar"
          confirmColor="red"
          confirmText="Descartar"
          opened={confirmExit}
          title="Descartar cambios"
          onCancel={() => setConfirmExit(false)}
          onConfirm={onClose}
        >
          <Text className="cd-text-base">
            Has realizado cambios en el formulario, ¿Deseas cerrarlo y descartar los cambios?
          </Text>
        </ConfirmationModal>
      </Modal>
    )
  }

  return content

  function onClose() {
    outerOnClose?.()
    setConfirmExit(false)
    formTool.resetDirty()
  }
}

export default ToolForm
