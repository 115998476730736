import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useForm } from '@mantine/form'
import { useMutation } from '@tanstack/react-query'
import { notifications } from '@mantine/notifications'
import { zodResolver } from 'mantine-form-zod-resolver'
import { ActionIcon, Button, Divider, Group, Modal, Paper, Table, Text } from '@mantine/core'

import {
  ClientBaseType,
  UpdateClientType,
  CreateClientSchema,
  CreateClientType,
  CreateContactType,
  ContactBaseType,
  CreateContactSchema,
} from '@customTypes/client'
import { ModalFormType, ModalProps } from '@customTypes/page'

import DataRepo from '@api/datasource/data'
import queryClient from '@api/datasource/query'

import QueryKeys from '@constants/queryKeys'

import { useIsMobile, useTableMinWidth } from '@hooks/mobile'

import { ErrorService } from '@utils/error'
import { invalidateDataQuery, isLoadingMutation } from '@utils/network'
import { buildSelectOptions, filterSelect } from '@utils/form'

import Input from '@components/shared/input'
import { PriorityClient, RepeatReminder } from '@constants/client'
import ConfirmationModal from '@components/shared/confirmationModal'
import moment from 'moment'
import { IconEdit, IconTrash } from '@tabler/icons-react'

const INITIAL: CreateClientType = {
  name: '',
  description: '',
  contacts: [],
  priority: 'medium',
  remainder: {
    date: 0,
    repeat: 'never',
  },
  location: '',
  phone: '',
  ruc: '',
}

const INITIAL_CONTACT: CreateContactType = {
  uid: '',
  name: '',
}

const ClientForm = (props: ModalProps<ClientBaseType>) => {
  const { open, modal, data, size = 'lg', onSubmitted, onClose: outerOnClose } = props

  const isMobile = useIsMobile()

  const minWidth = useTableMinWidth(isMobile)

  const [modalContact, setModalContact] = React.useState<
    ModalFormType<ClientBaseType['contacts'][0]>
  >({
    opened: false,
  })

  const [confirmExit, setConfirmExit] = React.useState(false)

  const formClient = useForm<CreateClientType>({
    initialValues: INITIAL,
    validate: zodResolver(CreateClientSchema),
  })

  const formContact = useForm<CreateContactType>({
    initialValues: INITIAL_CONTACT,
    validate: zodResolver(CreateContactSchema),
  })

  const clientCreateMutation = useMutation<ClientBaseType, ErrorService, CreateClientType>({
    mutationFn: async (data) => {
      const response = await DataRepo.clientEntityService.createClient(data)

      await queryClient.invalidateQueries({
        predicate: (query) => [QueryKeys.GET_CLIENTS_KEY].includes(query.queryKey[0] as string),
        refetchType: 'all',
      })

      onSubmitted?.(response)

      return response
    },
    onSettled: (_, error) => {
      if (error) {
        return notifications.show({
          color: 'red',
          title: 'Error',
          message: error.message ?? 'Error al crear la cliente',
        })
      }

      notifications.show({
        color: 'green',
        title: 'Éxito',
        message: 'Cliente creada correctamente',
      })

      onClose()
    },
  })

  const clientUpdateMutation = useMutation<void, ErrorService, UpdateClientType>({
    mutationFn: async (data) => {
      const response = await DataRepo.clientEntityService.updateClient(data)

      await invalidateDataQuery<ClientBaseType>({
        queryKeys: [QueryKeys.GET_CLIENT_KEY, data.uid],
        data: data as ClientBaseType,
        exact: true,
      })

      await queryClient.invalidateQueries({
        predicate: (query) => [QueryKeys.GET_CLIENTS_KEY].includes(query.queryKey[0] as string),
        refetchType: 'all',
      })

      return response
    },
    onSettled: (_, error) => {
      if (error) {
        return notifications.show({
          color: 'red',
          title: 'Error',
          message: error.message ?? 'Error al actualizar el cliente',
        })
      }

      notifications.show({
        color: 'green',
        title: 'Éxito',
        message: 'Cliente actualizado correctamente',
      })

      onSubmitted?.()

      onClose?.()
    },
  })

  React.useEffect(() => {
    if (open && data) {
      formClient.setValues(data)
    } else if (!open) {
      formClient.setValues(INITIAL)
    }
    formContact.setValues(INITIAL_CONTACT)
    formClient.resetDirty()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, data])

  React.useEffect(() => {
    if (modalContact.opened && modalContact.data) {
      formContact.setValues(modalContact.data)
    } else if (!modalContact.opened) {
      formContact.setValues(INITIAL_CONTACT)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalContact.data])

  const isLoading = isLoadingMutation(clientCreateMutation, clientUpdateMutation)

  const dateInputProps = formClient.getInputProps('remainder')

  const content = (
    <form
      onSubmit={formClient.onSubmit((values) => {
        if (data) {
          clientUpdateMutation.mutate({
            ...values,
            uid: data.uid,
          })
        } else {
          clientCreateMutation.mutate(values as CreateClientType)
        }
      })}
    >
      <div className="cd-flex cd-flex-col cd-gap-y-[1rem]">
        <Input
          label="Nombre"
          placeholder="Ingrese el nombre"
          typeInput="text"
          {...formClient.getInputProps('name')}
          onBlur={() => formClient.validateField('name')}
        />

        <Input
          className="cd-basis-[100%] md:cd-basis-1/2"
          label="Dirección"
          placeholder="Ingrese la dirección"
          typeInput="text"
          {...formClient.getInputProps('location')}
          onBlur={() => formClient.validateField('location')}
        />

        <Input
          autosize
          label="Descripción"
          maxRows={4}
          minRows={2}
          placeholder="Ingrese la descripción"
          typeInput="textarea"
          {...formClient.getInputProps('description')}
          onBlur={() => formClient.validateField('description')}
        />

        <div className="cd-flex cd-flex-col cd-gap-y-[1rem] md:cd-flex-row md:cd-gap-x-[1rem]">
          <Input
            className="cd-basis-[100%] md:cd-basis-1/2"
            label="Email"
            placeholder="Ingrese el email"
            typeInput="text"
            {...formClient.getInputProps('email')}
            onBlur={() => formClient.validateField('email')}
          />
          <Input
            searchable
            allowDeselect={false}
            className="cd-basis-[100%] md:cd-basis-1/2"
            data={buildSelectOptions({
              data: Object.values(PriorityClient),
              value: 'value',
              label: 'label',
            })}
            filter={(input) =>
              filterSelect({
                search: input.search,
                options: input.options,
              })
            }
            label="Prioridad"
            nothingFoundMessage="No se encontraron resultados"
            placeholder="Ingrese la prioridad"
            typeInput="select"
            {...formClient.getInputProps('priority')}
            onBlur={() => formClient.validateField('priority')}
          />
        </div>

        <div className="cd-flex cd-flex-col cd-gap-y-[1rem] md:cd-flex-row md:cd-gap-x-[1rem]">
          <Input
            className="cd-basis-[100%] md:cd-basis-1/2"
            label="RUC"
            placeholder="Ingrese el RUC"
            typeInput="text"
            {...formClient.getInputProps('ruc')}
            onBlur={() => formClient.validateField('ruc')}
          />

          <Input
            className="cd-basis-[100%] md:cd-basis-1/2"
            label="Teléfono"
            placeholder="Ingrese el teléfono"
            type="tel"
            typeInput="text"
            {...formClient.getInputProps('phone')}
            onBlur={() => formClient.validateField('phone')}
          />
        </div>

        <div className="cd-flex cd-flex-col cd-gap-y-[1rem] md:cd-flex-row md:cd-gap-x-[1rem]">
          <Input
            clearable
            className="cd-basis-[100%] md:cd-basis-1/2"
            dropdownType="popover"
            label="Recordatorio"
            locale="es"
            minDate={moment().toDate()}
            placeholder="Ingrese el recordatorio"
            typeInput="dateTimePicker"
            value={
              formClient.values.remainder?.date
                ? moment.unix(formClient.values.remainder.date).toDate()
                : null
            }
            onBlur={() => formClient.validateField('remainder.date')}
            onChange={(value) => {
              if (!value) {
                dateInputProps.onChange({
                  repeat: formClient.values.remainder?.repeat ?? 'never',
                  date: 0,
                })
                return
              }

              const valueMoment = moment(value)

              const isStartOfDay = valueMoment.isSame(valueMoment.clone().startOf('day'))

              dateInputProps.onChange({
                repeat: formClient.values.remainder?.repeat ?? 'never',
                date: isStartOfDay ? valueMoment.add(9, 'hours').unix() : valueMoment,
              })
            }}
          />
          <Input
            searchable
            allowDeselect={false}
            className="cd-basis-[100%] md:cd-basis-1/2"
            data={buildSelectOptions({
              data: Object.values(RepeatReminder),
              value: 'value',
              label: 'label',
            })}
            filter={(input) =>
              filterSelect({
                search: input.search,
                options: input.options,
              })
            }
            label="Repetir"
            nothingFoundMessage="No se encontraron resultados"
            placeholder="Ingrese el recordatorio"
            typeInput="select"
            value={formClient.values.remainder?.repeat}
            onBlur={() => formClient.validateField('remainder')}
            onChange={(value) => {
              dateInputProps.onChange({
                repeat: value,
                date: formClient.values.remainder?.date ?? 0,
              })
            }}
          />
        </div>

        <Divider label="Contactos" />

        <div className="cd-flex cd-flex-row md:cd-justify-end">
          <Button
            fullWidth={isMobile}
            type="button"
            onClick={() => setModalContact({ opened: true })}
          >
            Agregar contacto
          </Button>
        </div>

        <Paper withBorder>
          <Table.ScrollContainer minWidth={minWidth + (isMobile ? 450 : 0)} type="native">
            <Table striped withColumnBorders withRowBorders className="cd-w-full">
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>Nombre</Table.Th>
                  <Table.Th>Correo</Table.Th>
                  <Table.Th>Teléfono</Table.Th>
                  <Table.Th>Acciones</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {Boolean(formClient.values.contacts.length) &&
                  formClient.values.contacts.map((contact, index) => (
                    <Table.Tr key={`contact-${index}`}>
                      <Table.Td className={contact.master ? 'cd-font-bold' : ''}>
                        {contact.name} {contact.master && '(Principal)'}
                      </Table.Td>
                      <Table.Td className={contact.master ? 'cd-font-bold' : ''}>
                        {contact.email}
                      </Table.Td>
                      <Table.Td className={contact.master ? 'cd-font-bold' : ''}>
                        {contact.phone}
                      </Table.Td>
                      <Table.Td className="">
                        <Group justify="center">
                          <ActionIcon
                            variant="transparent"
                            onClick={() => {
                              setModalContact({ opened: true, data: contact })
                            }}
                          >
                            <IconEdit size={18} />
                          </ActionIcon>
                          <ActionIcon
                            color="red"
                            variant="transparent"
                            onClick={() => {
                              const newContacts = formClient.values.contacts.filter(
                                (item) => item.uid !== contact.uid,
                              )
                              formClient.setFieldValue('contacts', newContacts)
                            }}
                          >
                            <IconTrash size={18} />
                          </ActionIcon>
                        </Group>
                      </Table.Td>
                    </Table.Tr>
                  ))}
                {!formClient.values.contacts.length && (
                  <Table.Tr>
                    <Table.Td className="cd-text-center" colSpan={4}>
                      No se han agregado contactos
                    </Table.Td>
                  </Table.Tr>
                )}
              </Table.Tbody>
            </Table>
          </Table.ScrollContainer>
        </Paper>

        <Modal
          centered
          opened={modalContact.opened}
          size="md"
          title="Agregar contacto"
          onClose={() => setModalContact({ opened: false })}
        >
          <form>
            <div className="cd-flex cd-flex-col cd-gap-y-[1rem]">
              <Input
                label="Nombre"
                placeholder="Ingrese el nombre"
                typeInput="text"
                {...formContact.getInputProps('name')}
                onBlur={() => formContact.validateField('name')}
              />

              <Input
                label="Es contacto principal"
                labelPosition="left"
                typeInput="switch"
                {...formContact.getInputProps('master', { type: 'checkbox' })}
                checked={Boolean(formContact.values.master)}
                onBlur={() => formContact.validateField('master')}
                onChange={(value) => {
                  const newContacts = formClient.values.contacts.map((contact) => {
                    if (contact.uid === formContact.values.uid) {
                      return {
                        ...contact,
                        master: Boolean(value.target.checked),
                      }
                    }
                    return {
                      ...contact,
                      master: false,
                    }
                  })
                  formContact.setFieldValue('master', Boolean(value.target.checked))
                  formClient.setFieldValue('contacts', newContacts, { forceUpdate: true })
                }}
              />

              <Input
                label="Correo"
                placeholder="Ingrese el correo"
                type="email"
                typeInput="text"
                {...formContact.getInputProps('email')}
                onBlur={() => formContact.validateField('email')}
              />

              <Input
                label="Teléfono"
                placeholder="Ingrese el teléfono"
                type="tel"
                typeInput="text"
                {...formContact.getInputProps('phone')}
                onBlur={() => formContact.validateField('phone')}
              />
            </div>

            <Button
              fullWidth
              className="cd-mt-[1rem]"
              disabled={!formContact.isValid()}
              loaderProps={{ type: 'dots' }}
              onClick={() => {
                formContact.onSubmit((values) => {
                  if (modalContact.data) {
                    const targetId = modalContact.data.uid
                    const newContacts = formClient.values.contacts.map((contact) => {
                      if (contact.uid === targetId) {
                        return {
                          ...contact,
                          ...values,
                        }
                      }
                      return contact
                    })
                    formClient.setFieldValue('contacts', newContacts)
                  } else {
                    const newContact: ContactBaseType = {
                      ...values,
                      uid: uuidv4(),
                      updatedAt: moment().unix(),
                    }

                    formClient.setFieldValue('contacts', [
                      ...formClient.values.contacts,
                      newContact,
                    ])
                  }
                  formContact.reset()
                  setModalContact({ opened: false })
                })()
              }}
            >
              {modalContact.data ? 'Guardar contacto' : 'Crear contacto'}
            </Button>
          </form>
        </Modal>

        <Button
          className="cd-mt-[1rem]"
          disabled={!formClient.isValid()}
          loaderProps={{ type: 'dots' }}
          loading={isLoading}
          type="submit"
        >
          {data ? 'Guardar cliente' : 'Crear cliente'}
        </Button>
      </div>
    </form>
  )

  if (modal) {
    return (
      <Modal
        centered
        fullScreen={isMobile}
        opened={Boolean(open)}
        size={size}
        title={data ? 'Editar cliente' : 'Agregar cliente'}
        onClose={() => {
          if (formClient.isDirty()) {
            setConfirmExit(true)
          } else {
            onClose()
          }
        }}
      >
        {content}
        <ConfirmationModal
          cancelColor="gray"
          cancelText="Cancelar"
          confirmColor="red"
          confirmText="Descartar"
          opened={confirmExit}
          title="Descartar cambios"
          onCancel={() => setConfirmExit(false)}
          onConfirm={onClose}
        >
          <Text className="cd-text-base">
            Has realizado cambios en el formulario, ¿Deseas cerrarlo y descartar los cambios?
          </Text>
        </ConfirmationModal>
      </Modal>
    )
  }

  return content

  function onClose() {
    outerOnClose?.()
    setConfirmExit(false)
    formClient.reset()
  }
}

export default ClientForm
