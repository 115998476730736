import z from 'zod'
import { DocumentReference } from 'firebase/firestore'

import { CommitAction } from '@constants/commits'

import { UserSchema } from './user'
import { GetEntitySchema } from './network'
import { RawMaterialBaseSchema } from './rawMaterial'
import { ReceiptSchema } from './receipt'
import { WarehouseBaseSchema } from './warehouse'

export const CommitActionSchema = z.custom<keyof typeof CommitAction>(
  (value) => Object.keys(CommitAction).includes(value),
  {
    message: 'Acción inválida',
  },
)

// Used when get all commits
// this will be used always for a specific raw material
export const CommitBaseSchema = z.object({
  uid: z.string(),
  message: z
    .string()
    .min(3, 'El mensaje debe tener al menos 3 caracteres')
    .max(150, 'El mensaje debe tener como máximo 150 caracteres')
    .trim(),
  action: CommitActionSchema,
  updatedAt: z.number(),
  createdAt: z.number(),
  user: UserSchema,
  attachment: z.string().optional().nullable(),
  rawMaterials: z.array(
    z.lazy(() =>
      RawMaterialBaseSchema.omit({
        createdAt: true,
      }).extend({ quantity: z.number() }),
    ),
  ),
  receipts: z.array(
    z.lazy(() =>
      ReceiptSchema.omit({
        createdAt: true,
      }).extend({ quantity: z.number() }),
    ),
  ),
})

export type CommitBaseType = z.infer<typeof CommitBaseSchema>

//Used when get a commit by id (example: update a commit)
// because you need to know the warehouse and raw material of the commit
export const CommitSchema = CommitBaseSchema.omit({}).extend({
  warehouse: z.lazy(() => WarehouseBaseSchema),
})

export type CommitType = z.infer<typeof CommitSchema>

// Internal use
export const CommitFirebaseSchema = CommitBaseSchema.omit({
  user: true,
  rawMaterials: true,
  receipts: true,
}).extend({
  user: z.custom<DocumentReference>(),
  warehouse: z.custom<DocumentReference>(),
  rawMaterials: z.array(z.object({ ref: z.custom<DocumentReference>(), quantity: z.number() })),
  receipts: z.array(z.object({ ref: z.custom<DocumentReference>(), quantity: z.number() })),
})

export type CommitFirebaseType = z.infer<typeof CommitFirebaseSchema>

//
// CRUD OPERATIONS
//
//Create
export const CreateCommitSchema = z.object({
  warehouseID: z.string(),
  commit: CommitBaseSchema.omit({
    uid: true,
    user: true,
    createdAt: true,
    updatedAt: true,
  }),
})

export type CreateCommitType = z.infer<typeof CreateCommitSchema>

//Update
export const UpdateCommitSchema = z.object({
  warehouseID: z.string(),
  previous: CommitBaseSchema,
  current: CommitBaseSchema.omit({
    uid: true,
    user: true,
    createdAt: true,
    updatedAt: true,
  }),
})

export type UpdateCommitType = z.infer<typeof UpdateCommitSchema>

//
// GET OPERATIONS
//
//Get commits
export const GetCommitsSchema = GetEntitySchema.extend({
  warehouseID: z.string(),
  startDate: z.number().optional(),
  endDate: z.number().optional(),
})

export type GetCommitsType = z.infer<typeof GetCommitsSchema>
