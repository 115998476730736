import React from 'react'
import { ActionIcon, Group, Text, rem } from '@mantine/core'
import { IconUpload, IconPhoto, IconX, IconTrash } from '@tabler/icons-react'
import { Dropzone as DropzoneMantine, DropzoneProps, IMAGE_MIME_TYPE } from '@mantine/dropzone'
import { MAX_FILE_SIZE } from '@constants/app'
import { filesImageToBase64 } from '@utils/image'
import { notifications } from '@mantine/notifications'
import { $ } from '@utils/styles'

type DropzoneCustomProps = {
  files: string[]
  label?: string
  className?: string
  onDrop: (files: string[]) => void
} & Partial<Omit<DropzoneProps, 'onDrop'>>

export function Dropzone(props: DropzoneCustomProps) {
  const { files, className, onDrop, disabled, ...rest } = props

  return (
    <div className={className}>
      {props.label && <Text className="cd-text-[14px] cd-mb-[0.5rem]">{props.label}</Text>}
      <DropzoneMantine
        accept={IMAGE_MIME_TYPE}
        className={$(disabled && 'cd-opacity-50 cd-cursor-not-allowed')}
        disabled={disabled}
        maxSize={MAX_FILE_SIZE}
        {...rest}
        onDrop={(f) =>
          filesImageToBase64(f)
            .then((images) => onDrop([...files, ...images]))
            .catch(() =>
              notifications.show({
                color: 'red',
                title: 'Error',
                message: 'Error al cargar la imagen',
              }),
            )
        }
        onReject={() =>
          notifications.show({ color: 'red', title: 'Error', message: 'Archivo no permitido' })
        }
      >
        <Group gap="xl" justify="center">
          <React.Fragment>
            <DropzoneMantine.Accept>
              <IconUpload
                stroke={1.5}
                style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-blue-6)' }}
              />
            </DropzoneMantine.Accept>
            <DropzoneMantine.Reject>
              <IconX
                stroke={1.5}
                style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-red-6)' }}
              />
            </DropzoneMantine.Reject>
            <DropzoneMantine.Idle>
              <IconPhoto
                stroke={1.5}
                style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-dimmed)' }}
              />
            </DropzoneMantine.Idle>
          </React.Fragment>
          <div>
            <Text inline size="lg">
              Arrastra y suelta tus archivos aquí
            </Text>
            <Text inline c="dimmed" className="cd-text-sm cd-mt-[0.25rem]">
              O da click para buscarlos Capacidad máxima de archivo: {MAX_FILE_SIZE / 1024 / 1024}{' '}
              MB
            </Text>
          </div>
        </Group>
      </DropzoneMantine>
      {files.length > 0 && (
        <div className="cd-relative cd-flex cd-flex-row cd-mt-[1rem]">
          {files.map((file, index) => (
            <div className="cd-relative cd-basis-[25%] cd-p-[0.25rem]" key={index}>
              <img
                alt="file"
                className="cd-h-[100px] cd-w-full cd-cursor-default cd-object-cover cd-rounded-md"
                src={file}
              />
              <ActionIcon
                className="cd-absolute cd-top-[0.5rem] cd-right-[0.5rem] cd-rounded-full"
                color="red"
                size="lg"
                variant="filled"
                onClick={(e) => {
                  e.preventDefault()
                  const newFiles = files.filter((_, i) => i !== index)
                  onDrop(newFiles)
                }}
              >
                <IconTrash size={20} />
              </ActionIcon>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
