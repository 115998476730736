export const CommitAction = {
  consume: {
    label: 'Consumo',
    value: 'consume',
  },
  refill: {
    label: 'Reabastecimiento',
    value: 'refill',
  },
  move: {
    label: 'Movimiento',
    value: 'move',
  },
}
