import z from 'zod'
import { DocumentReference } from 'firebase/firestore'

import { GetEntitySchema } from './network'
import { ToolBaseSchema } from './tool'

// Used when get all warehouses
export const WarehouseToolBaseSchema = z.object({
  uid: z.string(),
  name: z
    .string()
    .min(3, 'Nombre debe tener al menos 3 caracteres')
    .max(50, 'Nombre debe tener como máximo 50 caracteres')
    .trim(),
  description: z.string().max(100, 'Descripción debe tener como máximo 100 caracteres').trim(),
  inHouse: z.boolean(),
  deleted: z.boolean().optional(),
  updatedAt: z.number(),
  createdAt: z.number(),
})

export type WarehouseToolBaseType = z.infer<typeof WarehouseToolBaseSchema>

// Used when get a warehouse by id (example: show materials by warehouse)
export const WarehouseToolSchema = WarehouseToolBaseSchema.extend({
  tools: z.array(z.lazy(() => ToolBaseSchema.extend({ stock: z.number() }))),
})

export type WarehouseToolType = z.infer<typeof WarehouseToolSchema>

// Internal use
export const WarehouseToolFirebaseSchema = WarehouseToolSchema.omit({
  tools: true,
}).extend({
  tools: z.array(z.custom<DocumentReference>()),
})

export type WarehouseToolFirebaseType = z.infer<typeof WarehouseToolFirebaseSchema>

//
// CRUD OPERATIONS
//
//Create
export const CreateWarehouseToolSchema = WarehouseToolSchema.omit({
  uid: true,
  deleted: true,
  updatedAt: true,
  createdAt: true,
})

export type CreateWarehouseToolType = z.infer<typeof CreateWarehouseToolSchema>

//Update
export const UpdateWarehouseToolSchema = WarehouseToolSchema.partial().extend({
  uid: z.string(),
})

export type UpdateWarehouseToolType = z.infer<typeof UpdateWarehouseToolSchema>

// Get warehouses
export const GetWarehouseToolsSchema = GetEntitySchema

export type GetWarehouseToolsType = z.infer<typeof GetWarehouseToolsSchema>

//Move tools
export const MoveToolsSchema = z.object({
  warehouseToolFromID: z.string().min(1, 'Debe seleccionar un almacén de origen'),
  warehouseToolToID: z.string().min(1, 'Debe seleccionar un almacén de destino'),
  tools: z
    .array(
      z.object({
        toolID: z.string(),
        quantity: z.number().positive('La cantidad debe ser mayor a 0'),
      }),
    )
    .min(1, 'Debe seleccionar al menos un material'),
})

export type MoveToolsType = z.infer<typeof MoveToolsSchema>

//Delete tools from warehouse
export const DeleteToolsFromWarehouseToolSchema = z.object({
  warehouseToolID: z.string(),
  toolIDs: z.array(z.string()).min(1, 'Debe seleccionar al menos un material'),
})

export type DeleteToolsFromWarehouseToolType = z.infer<typeof DeleteToolsFromWarehouseToolSchema>
