import React from 'react'
import { List, Text, Title, Badge, Button, Divider, Container, ActionIcon } from '@mantine/core'
import { useListState, useLocalStorage } from '@mantine/hooks'
import { notifications } from '@mantine/notifications'
import { useMutation, useQuery } from '@tanstack/react-query'
import { IconCubePlus, IconHomeMove, IconSearch, IconTrash } from '@tabler/icons-react'

import {
  WarehouseType,
  WarehouseBaseType,
  GetWarehousesType,
  DeleteRawMaterialsFromWarehouseType,
} from '@customTypes/warehouse'
import { ModalFormType } from '@customTypes/page'
import { GetRawMaterialsType, RawMaterialBaseType } from '@customTypes/rawMaterial'

import DataRepo from '@api/datasource/data'
import queryClient from '@api/datasource/query'

import { UI } from '@constants/app'
import QueryKeys from '@constants/queryKeys'

import { useParams } from '@hooks/params'

import { getColorStock } from '@utils/string'
import { buildSelectOptions } from '@utils/form'
import { ErrorCodes, ErrorService } from '@utils/error'
import { onFilterRawMaterial } from '@utils/rawMaterial'
import { isLoadingMutation, isLoadingOrRefetchQuery } from '@utils/network'

import Input from '@components/shared/input'
import WarehouseForm from '@components/warehouse/form'
import ConfirmationModal from '@components/shared/confirmationModal'
import CommitForm, { CommitFormType } from '@components/commits/form'
import TableCustom, { GenericColumnType } from '@components/shared/table'
import MoveRawMaterials, { MoveRawMaterialType } from '@components/warehouse/moveRawMaterials'
import CommitsRawMaterial, { CommitRawMaterialType } from '@components/commits/commitsWarehouse'
import { useIsMobile, useTableMinWidth } from '@hooks/mobile'
import NumberFormat from '@components/shared/Number'
import TabsGroups, { TabType } from '@components/shared/tabsGroups'
import { onFilterReceipt } from '@utils/receipt'
import RawMaterialPreview from '@components/rawMaterial/preview'

type QueryKeysType = (string | null)[]

const Warehouse = () => {
  const isMobile = useIsMobile()

  const minWidth = useTableMinWidth(isMobile)

  const [selectedRMs, handlersSelectedRMs] = useListState<string>([])

  const [selectedReceipts, handlersSelectedReceipts] = useListState<string>([])

  const [modalDeleteRMs, setModalDeleteRMs] = React.useState<WarehouseType['rawMaterials'] | null>(
    null,
  )

  const [modalDeleteReceipts, setModalDeleteReceipts] = React.useState<
    WarehouseType['receipts'] | null
  >(null)

  const [warehouseID, setWarehouseId] = useLocalStorage<string | null>({
    key: 'state-warehouseID',
    defaultValue: null,
  })

  const [modalForm, setModalForm] = React.useState<ModalFormType<WarehouseBaseType>>({
    opened: false,
  })

  const [modalPreview, setModalPreview] = React.useState<ModalFormType<RawMaterialBaseType>>({
    opened: false,
  })

  const [modalCommits, setModalCommits] = React.useState<ModalFormType<CommitRawMaterialType>>({
    opened: false,
  })

  const [modalCommitForm, setModalCommitForm] = React.useState<ModalFormType<CommitFormType>>({
    opened: false,
  })

  const [modalWarehouseDelete, setModalWarehouseDelete] = React.useState<
    ModalFormType<WarehouseType>
  >({
    opened: false,
  })

  const [modalRawMaterialMove, setModalRawMaterialMove] = React.useState<
    ModalFormType<MoveRawMaterialType>
  >({
    opened: false,
  })

  const [params, setParams] = useParams<GetWarehousesType>({
    queryKey: QueryKeys.GET_WAREHOUSES_KEY,
    initialParams: {},
  })

  const [paramsRawMaterial, setParamsRawMaterial] = useParams<GetRawMaterialsType>({
    queryKey: QueryKeys.GET_MATERIALS_KEY,
    initialParams: {},
  })

  const [paramsReceipt, setParamsReceipt] = useParams<GetRawMaterialsType>({
    queryKey: QueryKeys.GET_RECEIPTS_EXTENDED_KEY,
    initialParams: {},
  })

  const warehousesQuery = useQuery<
    WarehouseBaseType[],
    ErrorService,
    WarehouseBaseType[],
    [string, GetWarehousesType]
  >({
    queryKey: [QueryKeys.GET_WAREHOUSES_KEY, params],
    queryFn: async ({ queryKey }) => {
      const response = await DataRepo.warehouseEntitiesService.getWarehouses(queryKey[1])

      return response
    },
    initialData: [],
  })

  const warehouseQuery = useQuery<WarehouseType, ErrorService, WarehouseType, QueryKeysType>({
    enabled: Boolean(warehouseID) && warehouseID !== 'null',
    queryKey: [QueryKeys.GET_WAREHOUSE_KEY, warehouseID],
    queryFn: async ({ queryKey }) => {
      if (!queryKey[1]) {
        throw ErrorService.get(ErrorCodes.INVALID_PARAMS)
      }
      const response = await DataRepo.warehouseEntitiesService.getWarehouseById(queryKey[1])

      return response
    },
  })

  const warehouseDelete = useMutation<void, ErrorService, string>({
    mutationFn: async (data) => {
      await DataRepo.warehouseEntitiesService.deleteWarehouse(data)

      setParams({})

      await queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === QueryKeys.GET_WAREHOUSES_KEY,
        refetchType: 'all',
      })
    },
    onSettled: (_, error) => {
      if (error) {
        return notifications.show({
          color: 'red',
          title: 'Error',
          message: error.message || 'Ocurrió un error al eliminar la bodega',
        })
      }

      notifications.show({
        color: 'green',
        title: 'Bodega eliminada',
        message: 'La bodega se eliminó correctamente',
      })

      setWarehouseId(null)
      setModalWarehouseDelete({ opened: false })
    },
  })

  const removeItemsFromWarehouse = useMutation<
    void,
    ErrorService,
    DeleteRawMaterialsFromWarehouseType
  >({
    mutationFn: async (data) => {
      const response = await DataRepo.warehouseEntitiesService.deleteRawMaterialsFromWarehouse(data)

      await queryClient.invalidateQueries({
        queryKey: [QueryKeys.GET_WAREHOUSE_KEY, warehouseID],
        refetchType: 'all',
        exact: true,
      })

      await queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === QueryKeys.GET_WAREHOUSES_KEY,
        refetchType: 'all',
      })

      return response
    },
    onSettled: (_, error) => {
      if (error) {
        return notifications.show({
          color: 'red',
          title: 'Error',
          message: error.message || 'Ocurrió un error al eliminar la materia prima de la bodega',
        })
      }

      notifications.show({
        color: 'green',
        title: `${modalDeleteRMs ? 'Materia prima' : 'Receta'} eliminada`,
        message: `La ${
          modalDeleteRMs ? 'materia prima' : 'receta'
        } se eliminó correctamente de la bodega`,
      })

      if (modalDeleteRMs) {
        setModalDeleteRMs(null)
        handlersSelectedRMs.setState([])
      } else if (modalDeleteReceipts) {
        setModalDeleteReceipts(null)
        handlersSelectedReceipts.setState([])
      }
    },
  })

  React.useEffect(() => {
    return () => {
      handlersSelectedRMs.setState([])
      handlersSelectedReceipts.setState([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const memoFilterRawMaterial = React.useMemo(
    () =>
      onFilterRawMaterial<WarehouseType['rawMaterials'][0]>({
        data: warehouseQuery.data?.rawMaterials,
        params: paramsRawMaterial,
      }),
    [warehouseQuery.data?.rawMaterials, paramsRawMaterial],
  )

  const memoFilterReceipts = React.useMemo(
    () =>
      onFilterReceipt<WarehouseType['receipts'][0]>({
        data: warehouseQuery.data?.receipts,
        params: paramsReceipt,
      }),
    [warehouseQuery.data?.receipts, paramsReceipt],
  )

  return (
    <Container className="cd-mt-[1rem] cd-mb-[2rem] md:cd-mt-[2rem] md:cd-pb-[4rem]" size="xl">
      <div className="cd-flex cd-flex-col cd-gap-y-[1rem]">
        <div className="cd-flex cd-flex-col md:cd-flex-row cd-gap-[1rem] cd-grow">
          <Text className="cd-text-lg md:cd-basis-[80%]">Bodega de materiales</Text>
          <Button
            className="md:cd-basis-[20%]"
            color="green"
            leftSection={<IconCubePlus size={18} />}
            size={UI.Size}
            variant="filled"
            onClick={() => setModalForm({ opened: true })}
          >
            Agregar
          </Button>
        </div>

        <Input
          searchable
          data={buildSelectOptions({
            data: warehousesQuery.data,
            value: 'uid',
            label: 'name',
          })}
          loading={isLoadingOrRefetchQuery(warehousesQuery)}
          nothingFoundMessage="No se encontraron bodegas"
          placeholder="Seleccione una bodega para ver las materias primas"
          rightSection={<IconSearch size={18} />}
          typeInput="select"
          value={warehouseID}
          onChange={(v) => setWarehouseId(v)}
        />

        <Divider className="cd-my-[1rem]" />

        {warehouseQuery.data && (
          <div>
            <div className="cd-flex cd-gap-y-[0.25rem] cd-flex-row cd-justify-between cd-items-start cd-align-top">
              <Title className="cd-text-xl cd-grow">{warehouseQuery.data.name}</Title>
              <Badge
                className="cd-min-w-[100px]"
                color={warehouseQuery.data.inHouse ? 'green' : 'orange'}
                size="lg"
                variant="light"
              >
                {warehouseQuery.data.inHouse ? 'Interna' : 'Externa'}
              </Badge>
            </div>
            <Text className="cd-text-base">{warehouseQuery.data.description}</Text>

            <div className="cd-mt-[1rem] cd-flex cd-flex-col md:cd-flex-row cd-grow cd-justify-end cd-gap-[1rem]">
              <div className="cd-flex cd-flex-col md:cd-flex-row cd-grow cd-justify-end cd-gap-[1rem] md:cd-basis-[80%]">
                <div className="md:cd-basis-[80%]" />
                <Button
                  fullWidth
                  className="md:cd-basis-[20%]"
                  color="gray"
                  disabled={selectedRMs.length > 0}
                  onClick={() => {
                    if (!warehouseID) return

                    setModalCommits({
                      opened: true,
                      data: {
                        warehouseID,
                        warehouseName: warehouseQuery.data.name,
                      },
                    })
                  }}
                >
                  Ver registros
                </Button>
              </div>

              <div className="md:cd-basis-[20%] cd-flex md:cd-flex-row cd-gap-[0.5rem] cd-grow">
                <Button
                  fullWidth
                  color="blue"
                  disabled={!warehouseQuery.data || selectedRMs.length > 0}
                  onClick={() => {
                    if (!warehouseQuery.data) return

                    setModalForm({ opened: true, data: warehouseQuery.data })
                  }}
                >
                  Editar bodega
                </Button>
                <ActionIcon
                  color="red"
                  disabled={!warehouseID || selectedRMs.length > 0}
                  size={36}
                  variant="light"
                  onClick={() =>
                    setModalWarehouseDelete({ opened: true, data: warehouseQuery.data! })
                  }
                >
                  <IconTrash size={18} />
                </ActionIcon>
              </div>
            </div>
          </div>
        )}

        <TabsGroups
          fullWidth
          size="sm"
          tabs={buildTabs()}
          onChange={() => {
            handlersSelectedRMs.setState([])
            handlersSelectedReceipts.setState([])
          }}
        />

        <RawMaterialPreview
          modal
          data={modalPreview.data}
          open={modalPreview.opened}
          onClose={() => setModalPreview({ opened: false })}
        />

        <WarehouseForm
          modal
          data={modalForm.data}
          open={modalForm.opened}
          size="xl"
          onClose={() => setModalForm({ opened: false })}
          onSubmitted={() => null}
        />

        <CommitsRawMaterial
          modal
          data={modalCommits.data}
          open={modalCommits.opened}
          size="95%"
          onAddCommit={() => {
            if (!warehouseID || !warehouseQuery.data) return

            setModalCommitForm({
              opened: true,
              data: {
                warehouseID,
                warehouseName: warehouseQuery.data?.name ?? '',
                rawMaterialsWarehouse: warehouseQuery.data.rawMaterials,
                receiptsWarehouse: warehouseQuery.data.receipts,
              },
            })
          }}
          onClose={() => setModalCommits({ opened: false })}
          onEditCommit={(commit) => {
            if (!warehouseID || !warehouseQuery.data) return

            setModalCommitForm({
              opened: true,
              data: {
                commit,
                warehouseID,
                warehouseName: warehouseQuery.data?.name ?? '',
                rawMaterialsWarehouse: warehouseQuery.data.rawMaterials,
                receiptsWarehouse: warehouseQuery.data.receipts,
              },
            })
          }}
        />

        <MoveRawMaterials
          modal
          data={modalRawMaterialMove.data}
          open={modalRawMaterialMove.opened}
          size="xl"
          onClose={() => setModalRawMaterialMove({ opened: false })}
          onSubmitted={() => {
            handlersSelectedRMs.setState([])
            handlersSelectedReceipts.setState([])
          }}
        />

        <CommitForm
          modal
          data={modalCommitForm.data}
          open={modalCommitForm.opened}
          size="xl"
          onClose={() => setModalCommitForm({ opened: false })}
        />

        {/* Confirmación de materias primas en bodega  */}
        <ConfirmationModal
          cancelColor="gray"
          cancelText="Cancelar"
          confirmColor="red"
          confirmText="Eliminar"
          loading={isLoadingMutation(removeItemsFromWarehouse)}
          opened={Boolean(modalDeleteRMs?.length)}
          title="Eliminar materia prima de bodega"
          onCancel={() => setModalDeleteRMs(null)}
          onConfirm={() => {
            if (!modalDeleteRMs || !warehouseQuery.data) return

            removeItemsFromWarehouse.mutate({
              warehouseID: warehouseQuery.data.uid,
              rawMaterialIDs: modalDeleteRMs.map((item) => item.uid),
              receiptIDs: [],
            })
          }}
        >
          {modalDeleteRMs?.length === 1 && (
            <Text className="cd-text-base">
              ¿Estás seguro que deseas eliminar la materia prima{' '}
              <Text inherit className="cd-text-base cd-font-bold" component="span">
                {modalDeleteRMs[0].name}
              </Text>
              ?
            </Text>
          )}
          {modalDeleteRMs && modalDeleteRMs?.length > 1 && (
            <React.Fragment>
              <Text className="cd-text-base">
                ¿Estás seguro que deseas eliminar las siguientes materias primas?
              </Text>
              <List withPadding className="cd-mt-[0.5rem] cd-list-disc" type="unordered">
                {modalDeleteRMs.map((item) => (
                  <List.Item key={item.uid}>
                    <Text className="cd-text-base">{item.name}</Text>
                  </List.Item>
                ))}
              </List>
            </React.Fragment>
          )}
        </ConfirmationModal>

        {/* Confirmación de recetas de bodega  */}
        <ConfirmationModal
          cancelColor="gray"
          cancelText="Cancelar"
          confirmColor="red"
          confirmText="Eliminar"
          loading={isLoadingMutation(removeItemsFromWarehouse)}
          opened={Boolean(modalDeleteReceipts?.length)}
          title="Eliminar receta de bodega"
          onCancel={() => setModalDeleteRMs(null)}
          onConfirm={() => {
            if (!modalDeleteReceipts || !warehouseQuery.data) return

            removeItemsFromWarehouse.mutate({
              warehouseID: warehouseQuery.data.uid,
              rawMaterialIDs: [],
              receiptIDs: modalDeleteReceipts.map((item) => item.uid),
            })
          }}
        >
          {modalDeleteReceipts?.length === 1 && (
            <Text className="cd-text-base">
              ¿Estás seguro que deseas eliminar la materia prima{' '}
              <Text inherit className="cd-text-base cd-font-bold" component="span">
                {modalDeleteReceipts[0].uid} - {modalDeleteReceipts[0].name}
              </Text>
              ?
            </Text>
          )}
          {modalDeleteReceipts && modalDeleteReceipts?.length > 1 && (
            <React.Fragment>
              <Text className="cd-text-base">
                ¿Estás seguro que deseas eliminar las siguientes materias primas?
              </Text>
              <List withPadding className="cd-mt-[0.5rem] cd-list-disc" type="unordered">
                {modalDeleteReceipts.map((item) => (
                  <List.Item key={item.uid}>
                    <Text className="cd-text-base">
                      {item.uid} - {item.name}
                    </Text>
                  </List.Item>
                ))}
              </List>
            </React.Fragment>
          )}
        </ConfirmationModal>

        <ConfirmationModal
          cancelColor="gray"
          cancelText="Cancelar"
          confirmColor="red"
          confirmText="Eliminar"
          loading={isLoadingMutation(warehouseDelete)}
          opened={modalWarehouseDelete.opened}
          title="Eliminar bodega"
          onCancel={() => setModalWarehouseDelete({ opened: false })}
          onConfirm={() => {
            if (!modalWarehouseDelete.data) return

            warehouseDelete.mutate(modalWarehouseDelete.data.uid)
          }}
        >
          <Text className="cd-text-base">
            ¿Estás seguro que deseas eliminar la bodega{' '}
            <Text inherit className="cd-text-base cd-font-bold" component="span">
              {modalWarehouseDelete.data?.name}
            </Text>
            ? Esta bodega es de tipo{' '}
            {
              <Text inherit className="cd-font-bold" component="span">
                {modalWarehouseDelete.data?.inHouse ? 'Interna' : 'Externa'}
              </Text>
            }
            .
          </Text>
        </ConfirmationModal>
      </div>
    </Container>
  )

  function buildTabs() {
    const tabs: TabType[] = [
      {
        label: 'Materias primas',
        value: 'rawMaterials',
        content: (
          <TableCustom<WarehouseType['rawMaterials'][0]>
            allData={warehouseQuery.data?.rawMaterials}
            columns={buildColumnsRMs()}
            data={memoFilterRawMaterial}
            defaultColumnSearch="name"
            extraActions={buildExtraOptions()}
            keyId="uid"
            loading={isLoadingOrRefetchQuery(warehouseQuery)}
            minWidth={minWidth}
            noDataMessage={
              warehouseID
                ? 'No se encontraron materias primas'
                : 'Seleccione una bodega para ver las materias primas'
            }
            selectedRows={selectedRMs}
            onChangeSelected={handlersSelectedRMs}
            onDelete={(ids) => {
              if (!warehouseQuery.data) return

              const filtered = warehouseQuery.data.rawMaterials.filter(
                (item) => !ids.includes(item.uid),
              )

              setModalDeleteRMs(filtered)
            }}
            onDetail={(item) => {
              setModalPreview({ opened: true, data: item })
            }}
            onSearch={(query, field) => setParamsRawMaterial({ query, field })}
          />
        ),
      },
      {
        label: 'Recetas',
        value: 'receipts',
        content: (
          <TableCustom<WarehouseType['receipts'][0]>
            allData={warehouseQuery.data?.receipts}
            columns={buildColumnsReceipts()}
            data={memoFilterReceipts}
            defaultColumnSearch="name"
            extraActions={buildExtraOptions()}
            keyId="uid"
            loading={isLoadingOrRefetchQuery(warehouseQuery)}
            minWidth={minWidth}
            noDataMessage={
              warehouseID
                ? 'No se encontraron recetas'
                : 'Seleccione una bodega para ver las recetas'
            }
            selectedRows={selectedReceipts}
            onChangeSelected={handlersSelectedReceipts}
            onDelete={(ids) => {
              if (!warehouseQuery.data) return

              const filtered = warehouseQuery.data.receipts.filter(
                (item) => !ids.includes(item.uid),
              )

              setModalDeleteReceipts(filtered)
            }}
            onSearch={(query, field) => setParamsReceipt({ query, field })}
          />
        ),
      },
    ]

    return tabs
  }

  function buildColumnsRMs() {
    return [
      { key: 'name', label: 'Nombre', width: '5%', type: 'text', searchable: true },
      { key: 'description', label: 'Descripción', width: '15%', type: 'text', searchable: true },
      { key: 'measure', label: 'Medida', width: '3%', type: 'text', searchable: true },
      {
        key: 'stock',
        label: 'Stock',
        width: '3%',
        type: 'calc',
        align: 'right',
        defaultOnClick: true,
        render: (item) => (
          <Text span c={getColorStock(item.stock, item.alertStock)} size="sm">
            {<NumberFormat decimals={0} prefix="" value={item.stock} />}
          </Text>
        ),
      },
      { key: 'alertStock', label: 'Alerta', width: '3%', type: 'text', align: 'right' },
      {
        key: 'value',
        label: 'Valor ($)',
        width: '3%',
        type: 'calc',
        align: 'right',
        render: ({ value }) => <NumberFormat value={value} />,
      },
    ] as GenericColumnType<WarehouseType['rawMaterials'][0]>[]
  }

  function buildColumnsReceipts() {
    return [
      { key: 'identifier', label: 'ID', width: '10%', type: 'text', searchable: true },
      { key: 'name', label: 'Nombre', width: '25%', type: 'text', searchable: true },
      { key: 'description', label: 'Descripción', width: '35%', type: 'text', searchable: true },
      {
        key: 'stock',
        label: 'Stock',
        width: '10%',
        type: 'calc',
        align: 'right',
        defaultOnClick: true,
        render: ({ stock }) => <NumberFormat decimals={0} prefix="" value={stock} />,
      },
      {
        key: 'value',
        label: 'Valor ($)',
        width: '10%',
        type: 'calc',
        align: 'right',
        render: ({ rawMaterials }) => (
          <NumberFormat value={rawMaterials.reduce((acc, rm) => acc + rm.value * rm.quantity, 0)} />
        ),
      },
    ] as GenericColumnType<WarehouseType['receipts'][0]>[]
  }

  function buildExtraOptions() {
    return (
      <React.Fragment>
        <Button
          className="md:cd-basis-[20%]"
          color="blue"
          leftSection={<IconHomeMove size={18} />}
          size={UI.Size}
          variant="filled"
          onClick={() => {
            const rawMaterials =
              warehouseQuery.data?.rawMaterials.filter(({ uid }) => selectedRMs.includes(uid)) || []

            const receipts =
              warehouseQuery.data?.receipts.filter(({ uid }) => selectedReceipts.includes(uid)) ||
              []

            if (!warehouseID || (!rawMaterials.length && !receipts.length)) return

            setModalRawMaterialMove({
              opened: true,
              data: {
                warehouseFromID: warehouseID,
                rawMaterials: rawMaterials.map((item) => ({
                  ...item,
                  maxQuantity: item.stock,
                })),
                receipts: receipts.map((item) => ({
                  ...item,
                  maxQuantity: item.stock,
                })),
              },
            })
          }}
        >
          Mover a bodega
        </Button>
      </React.Fragment>
    )
  }
}

export default Warehouse
