import React from 'react'
import { useDisclosure, useMediaQuery } from '@mantine/hooks'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import {
  IconAddressBook,
  IconBoxMultiple,
  IconBuildingWarehouse,
  IconCube,
  IconDatabaseExport,
  IconFileDescription,
  IconForklift,
  IconHelp,
  IconHome,
  IconLogout,
  IconMapPinPlus,
  IconMoon,
  IconReceipt,
  IconShoppingCart,
  IconSun,
  IconTools,
} from '@tabler/icons-react'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'
import {
  Anchor,
  AppShell,
  Burger,
  Loader,
  Flex,
  Text,
  Title,
  ActionIcon,
  Divider,
  useMantineColorScheme,
  Stack,
  Group,
} from '@mantine/core'

import { UserType } from '@customTypes/user'

import DataRepo from '@api/datasource/data'

import { useStoreBase } from '@store/index'

import { $ } from '@utils/styles'
import { Logger } from '@utils/log'
import { ErrorService } from '@utils/error'
import { transitionView } from '@utils/viewTransition'

import QueryKeys from '@constants/queryKeys'
import { RoutesApp } from '@constants/routes'

import TextIcon from '@components/shared/textIcon'
import { modals } from '@mantine/modals'
import { WEB_VERSION } from '@constants/app'
import { notifications } from '@mantine/notifications'

export const HEIGHT_DRAWER = 50

export default function Protected() {
  const location = useLocation()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const [sectionSelected, setSectionSelected] = React.useState<string>('')

  const { user, setUser, reset: resetBase } = useStoreBase((state) => state)

  const [mobileOpened, { toggle: toggleMobile }] = useDisclosure(false)
  const [desktopOpened, { toggle: toggleDesktop }] = useDisclosure(true)

  const isMediaLowerLg = useMediaQuery('(max-width: 1024px)')

  console.log('isMediaLowerLg', isMediaLowerLg)

  const { toggleColorScheme, colorScheme } = useMantineColorScheme({
    keepTransitions: true,
  })

  const userQuery = useQuery<UserType | null, ErrorService>({
    queryKey: [QueryKeys.GET_USER_KEY],
    queryFn: async () => {
      const response = await DataRepo.userService.getUser()
      return response
    },
  })

  React.useEffect(
    () => {
      setSectionSelected(location.pathname.split('/')[1] ?? 'home')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.pathname],
  )

  React.useEffect(() => {
    queryClient.invalidateQueries({
      exact: true,
      refetchType: 'all',
      queryKey: [QueryKeys.GET_USER_KEY],
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if ([RoutesApp.LOGIN, RoutesApp.REGISTER].includes(location.pathname)) return

    if ((userQuery.isSuccess || userQuery.isError) && !userQuery.data) {
      Logger.info('Drawer: No user found, redirecting to login')
      notifications.show({
        color: 'blue',
        title: 'Sesión terminada',
        message: 'Por favor inicie sesión nuevamente',
      })
      navigate(RoutesApp.LOGIN)
    } else if (userQuery.data) {
      setUser(userQuery.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userQuery.isPending, userQuery.data, userQuery.isSuccess, userQuery.isError])

  const NavBarMemo = React.useMemo(
    buildNavBar,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accentSelected, user?.role, sectionSelected, isMediaLowerLg, toggleMobile],
  )

  if (!user) {
    return (
      <Flex align="center" direction="column" gap="md" h="100vh" justify="center" wrap="wrap">
        <Loader color="blue" size="lg" type="dots" />
        <Text size="lg">Cargando su información, por favor espere</Text>
      </Flex>
    )
  }

  return (
    <AppShell
      header={{ height: HEIGHT_DRAWER }}
      navbar={{
        width: 255,
        breakpoint: 'lg',
        collapsed: { mobile: !mobileOpened, desktop: !desktopOpened },
      }}
      padding="md"
    >
      <AppShell.Header>
        <div className="cd-h-full cd-flex cd-justify-between cd-items-center cd-px-[1rem]">
          <Flex align="center" gap="xs" justify="flex-start">
            <Burger hiddenFrom="lg" opened={mobileOpened} size="sm" onClick={toggleMobile} />
            <Burger opened={desktopOpened} size="sm" visibleFrom="lg" onClick={toggleDesktop} />
            <Link
              className={$('home-link-transition')}
              to={RoutesApp.HOME}
              onClick={(e) => {
                e.preventDefault()
                transitionView(() => navigate(RoutesApp.HOME))
                if (isMediaLowerLg) toggleMobile()
              }}
            >
              <Title className="cd-title-form" key="main-title" order={3}>
                Sein
                <Text inherit c="blue" component="span">
                  pro
                </Text>
              </Title>
            </Link>
          </Flex>
          <Flex align="center" gap="md" justify="flex-end">
            <ActionIcon
              variant="transparent"
              onClick={() => {
                if (document.startViewTransition) {
                  document.startViewTransition(toggleColorScheme)
                } else {
                  toggleColorScheme()
                }
              }}
            >
              {colorScheme === 'dark' && <IconSun size={24} />}
              {colorScheme === 'light' && <IconMoon size={24} />}
              {colorScheme === 'auto' && <IconMoon size={24} />}
            </ActionIcon>
            <ActionIcon variant="transparent" onClick={handleHelpModal}>
              <IconHelp size={24} />
            </ActionIcon>
          </Flex>
        </div>
      </AppShell.Header>
      <AppShell.Navbar p="md" title="Menú">
        {NavBarMemo}
      </AppShell.Navbar>
      <AppShell.Main className="cd-h-full" pt={HEIGHT_DRAWER}>
        <Outlet />
      </AppShell.Main>
    </AppShell>
  )

  function buildNavBar() {
    return (
      <div className="cd-flex cd-flex-col cd-gap-y-[1rem]">
        <Link
          className={accentSelected(RoutesApp.HOME)}
          to={RoutesApp.HOME}
          onClick={() => {
            if (isMediaLowerLg) toggleMobile()
          }}
        >
          <TextIcon leftIcon={<IconHome />}>
            <Text>Inicio</Text>
          </TextIcon>
        </Link>
        <Divider />
        {user?.role === 'admin' && (
          <React.Fragment>
            <Link
              className={accentSelected(RoutesApp.EXPORT)}
              to={RoutesApp.EXPORT}
              onClick={() => {
                if (isMediaLowerLg) toggleMobile()
              }}
            >
              <TextIcon leftIcon={<IconDatabaseExport />}>
                <Text>Exportar</Text>
              </TextIcon>
            </Link>
            <Divider />
          </React.Fragment>
        )}
        {user?.role === 'admin' && (
          <React.Fragment>
            <Link
              className={accentSelected(RoutesApp.GEO_CHECKPOINTS)}
              to={RoutesApp.GEO_CHECKPOINTS}
              onClick={() => {
                if (isMediaLowerLg) toggleMobile()
              }}
            >
              <TextIcon leftIcon={<IconMapPinPlus />}>
                <Text>Geo Checkpoints</Text>
              </TextIcon>
            </Link>
            <Divider />
          </React.Fragment>
        )}
        <Link
          className={accentSelected(RoutesApp.CLIENTS)}
          to={RoutesApp.CLIENTS}
          onClick={() => {
            if (isMediaLowerLg) toggleMobile()
          }}
        >
          <TextIcon leftIcon={<IconAddressBook />}>
            <Text>Clientes</Text>
          </TextIcon>
        </Link>
        <Divider />
        <Link
          className={accentSelected(RoutesApp.PROFORMAS)}
          to={RoutesApp.PROFORMAS}
          onClick={() => {
            if (isMediaLowerLg) toggleMobile()
          }}
        >
          <TextIcon leftIcon={<IconFileDescription />}>
            <Text>Proformas</Text>
          </TextIcon>
        </Link>
        <Divider />
        <Link
          className={accentSelected(RoutesApp.ORDER_PURCHASES)}
          to={RoutesApp.ORDER_PURCHASES}
          onClick={() => {
            if (isMediaLowerLg) toggleMobile()
          }}
        >
          <TextIcon leftIcon={<IconShoppingCart />}>
            <Text>Ordenes de compra</Text>
          </TextIcon>
        </Link>
        <Divider />
        <Link
          className={accentSelected(RoutesApp.BLOCK)}
          to={RoutesApp.BLOCK}
          onClick={() => {
            if (isMediaLowerLg) toggleMobile()
          }}
        >
          <TextIcon leftIcon={<IconBoxMultiple />}>
            <Text>Bloques</Text>
          </TextIcon>
        </Link>
        <Divider />
        <Link
          className={accentSelected(RoutesApp.RECEIPTS)}
          to={RoutesApp.RECEIPTS}
          onClick={() => {
            if (isMediaLowerLg) toggleMobile()
          }}
        >
          <TextIcon leftIcon={<IconReceipt />}>
            <Text>Recetas</Text>
          </TextIcon>
        </Link>
        <Divider />
        <Link
          className={accentSelected(RoutesApp.RAW_MATERIALS)}
          to={RoutesApp.RAW_MATERIALS}
          onClick={() => {
            if (isMediaLowerLg) toggleMobile()
          }}
        >
          <TextIcon leftIcon={<IconCube />}>
            <Text>Materias prima</Text>
          </TextIcon>
        </Link>
        <Divider />
        <Link
          className={
            RoutesApp.WAREHOUSE.includes(sectionSelected) &&
            !sectionSelected.includes(RoutesApp.WAREHOUSE_TOOLS)
              ? 'cd-text-blue-300'
              : ''
          }
          to={RoutesApp.WAREHOUSE}
          onClick={() => {
            if (isMediaLowerLg) toggleMobile()
          }}
        >
          <TextIcon leftIcon={<IconBuildingWarehouse />}>
            <Text>Bodega de materiales</Text>
          </TextIcon>
        </Link>
        <Divider />
        <Link
          className={accentSelected(RoutesApp.TOOLS)}
          to={RoutesApp.TOOLS}
          onClick={() => {
            if (isMediaLowerLg) toggleMobile()
          }}
        >
          <TextIcon leftIcon={<IconTools />}>
            <Text>Herramientas</Text>
          </TextIcon>
        </Link>
        <Divider />
        <Link
          className={
            RoutesApp.WAREHOUSE_TOOLS.includes(sectionSelected) && sectionSelected.includes('-')
              ? 'cd-text-blue-300'
              : ''
          }
          to={RoutesApp.WAREHOUSE_TOOLS}
          onClick={() => {
            if (isMediaLowerLg) toggleMobile()
          }}
        >
          <TextIcon leftIcon={<IconForklift />}>
            <Text>Bodega de herramientas</Text>
          </TextIcon>
        </Link>
        <Divider />
        <TextIcon
          className="cd-cursor-pointer"
          leftIcon={<IconLogout color="var(--mantine-color-red-5)" />}
          onClick={reset}
        >
          <Text>Cerrar sesión</Text>
        </TextIcon>
      </div>
    )
  }

  function accentSelected(path: string) {
    return path.includes(sectionSelected) ? 'cd-text-blue-300' : ''
  }

  function reset() {
    DataRepo.userService.logout().finally(() => {
      resetBase()
      localStorage.clear()
      navigate(RoutesApp.LOGIN)
    })
  }

  function handleHelpModal() {
    modals.open({
      centered: true,
      title: 'Soporte',
      size: 'lg',
      children: (
        <Stack gap="md">
          <Text>
            Si tienes alguna duda, puedes contactarme a través de mi correo electrónico{' '}
            <Anchor href="mailto:enmanuelmag@cardor.dev">enmanuelmag@cardor.dev</Anchor>
          </Text>

          <Group className="cd-mt-[1rem] cd-text-sm" justify="end">
            <Text c="dimmed">Version: {WEB_VERSION}</Text>
          </Group>
        </Stack>
      ),
    })
  }
}
