/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import moment from 'moment'
import { useForm, zodResolver } from '@mantine/form'
import { useNavigate } from 'react-router-dom'
import { IconDatabaseExport, IconTrash } from '@tabler/icons-react'
import { ActionIcon, Button, Container, Fieldset, Text } from '@mantine/core'

import { useStoreBase } from '@store/index'

import {
  GetExportEntityType,
  ExportEntitiesBulkCreateType,
  ExportEntitiesBulkCreateSchema,
} from '@customTypes/export'

import { UI } from '@constants/app'
import { RoutesApp } from '@constants/routes'
import { ExportEntities } from '@constants/export'

import { buildSelectOptions, filterSelect } from '@utils/form'

import Input from '@components/shared/input'
import { useMutation } from '@tanstack/react-query'
import { ErrorService } from '@utils/error'
import DataRepo from '@api/datasource/data'
import { notifications } from '@mantine/notifications'
import { isLoadingMutation } from '@utils/network'

const INITIAL: ExportEntitiesBulkCreateType = {
  data: [
    {
      entity: 'BLOCKS',
      filter: {
        allTimes: true,
        startDate: undefined,
        endDate: undefined,
      },
    },
  ],
}

const Export = () => {
  const navigate = useNavigate()

  const { user } = useStoreBase()

  React.useEffect(() => {
    if (!user || user.role !== 'admin') {
      return navigate(RoutesApp.HOME)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const formExport = useForm<ExportEntitiesBulkCreateType>({
    initialValues: INITIAL,
    validate: zodResolver(ExportEntitiesBulkCreateSchema),
  })

  const exportMutation = useMutation<void, ErrorService, ExportEntitiesBulkCreateType>({
    mutationFn: async ({ data }) => {
      return await DataRepo.documentEntitiesService.exportBulkData(data)
    },
    onSettled: (_, error) => {
      if (error) {
        return notifications.show({
          color: 'red',
          title: 'Error',
          message: error.message ?? 'Error al exportar entidades',
        })
      }

      notifications.show({
        color: 'green',
        title: 'Éxito',
        message: 'Entidades exportadas correctamente',
      })
    },
  })

  const formWatch = formExport.getValues()

  const currentEntities = formWatch.data.map((entity) => entity.entity)

  return (
    <Container className="cd-mt-[1rem] cd-mb-[2rem] md:cd-mt-[2rem] md:cd-pb-[4rem]" size="xl">
      <div className="cd-flex cd-flex-col cd-gap-y-[2rem]">
        <div className="cd-flex cd-flex-col cd-gap-[1rem] cd-grow">
          <Text className="cd-text-lg md:cd-basis-[80%]">Exportar entidades</Text>
          <Text className="cd-text-base">
            Seleccione las entidades y el rango de fechas que desea exportar
          </Text>
        </div>

        <form
          className="cd-flex cd-flex-col cd-gap-[1rem] cd-grow cd-mb-[1rem]"
          onSubmit={formExport.onSubmit((values) => exportMutation.mutate(values))}
        >
          <Fieldset className="cd-flex cd-flex-col cd-gap-y-[1rem]" legend="Entidades">
            {formWatch.data.map((entity, index) => (
              <div
                className="cd-flex cd-gap-x-[1rem] cd-gap-y-[1rem] cd-flex-col md:cd-flex-row md:cd-items-center cd-justify-between"
                key={index}
              >
                <Input
                  searchable
                  allowDeselect={false}
                  className="cd-basis-[100%] md:cd-basis-[35%]"
                  data={buildSelectOptions({
                    data: Object.values(ExportEntities),
                    blackList: currentEntities,
                    value: 'value',
                    label: 'label',
                  })}
                  filter={(input) =>
                    filterSelect({
                      search: input.search,
                      options: input.options,
                    })
                  }
                  label="Entidad"
                  nothingFoundMessage="No se encontraron resultados"
                  placeholder="Seleccione una entidad"
                  typeInput="select"
                  {...formExport.getInputProps(`data.${index}.entity`)}
                  value={entity.entity}
                  onBlur={() => formExport.validateField(`data.${index}.entity`)}
                />
                <Input
                  className="cd-basis-[100%] md:cd-basis-[50%]"
                  disabled={entity.filter.allTimes}
                  error={formExport.errors?.[`data.${index}.filter`]}
                  label="Filtro"
                  placeholder="Seleccione un rango de fechas"
                  type="range"
                  typeInput="dateRangePicker"
                  onChange={(value) => {
                    const castValue = value as unknown as [Date, Date]
                    const [startDate, endDate] = castValue

                    formExport.setFieldValue(
                      `data.${index}.filter`,
                      {
                        allTimes: false,
                        startDate: moment(startDate).startOf('day').unix(),
                        endDate: moment(endDate).endOf('day').unix(),
                      },
                      { forceUpdate: true },
                    )
                  }}
                />
                <div className="cd-mt-[1rem] cd-basis-[10%] cd-flex cd-gap-x-[1rem] cd-flex-row cd-items-center cd-justify-between md:cd-justify-between">
                  <Input
                    className="cd-basis-auto"
                    label="Todo"
                    {...formExport.getInputProps(`data.${index}.filter.allTimes`)}
                    checked={entity.filter.allTimes}
                    labelPosition="left"
                    size="lg"
                    typeInput="switch"
                    onChange={(event) => {
                      formExport.setFieldValue(
                        `data.${index}.filter`,
                        {
                          allTimes: event.target.checked,
                          startDate: undefined,
                          endDate: undefined,
                        },
                        { forceUpdate: true },
                      )
                    }}
                  />
                  <ActionIcon
                    className="cd-basis-auto cd-justify-center cd-flex"
                    color="gray"
                    disabled={formWatch.data.length === 1}
                    size={40}
                    variant="light"
                    onClick={() => {
                      formExport.removeListItem('data', index)
                    }}
                  >
                    <IconTrash size={24} />
                  </ActionIcon>
                </div>
              </div>
            ))}
            <div className="cd-flex cd-flex-col cd-gap-y-[0.5rem] md:cd-flex-row md:cd-gap-x-[1rem] md:cd-justify-end">
              <Button
                className="cd-mt-[1rem]"
                color="green"
                onClick={() => {
                  const newEntity: GetExportEntityType = {
                    entity: '' as keyof typeof ExportEntities,
                    filter: {
                      allTimes: true,
                      startDate: undefined,
                      endDate: undefined,
                    },
                  }

                  formExport.insertListItem('data', newEntity)
                }}
              >
                Agregar entidad
              </Button>
            </div>
          </Fieldset>
          <Button
            className="cd-mt-[1rem]"
            color="blue"
            leftSection={<IconDatabaseExport size={18} />}
            loading={isLoadingMutation(exportMutation)}
            size={UI.Size}
            type="submit"
            variant="filled"
          >
            Exportar
          </Button>
        </form>
      </div>
    </Container>
  )
}

export default Export
