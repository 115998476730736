import React from 'react'

import { GetRawMaterialsType } from '@customTypes/rawMaterial'

import queryClient from '@api/datasource/query'

type ParamsType<T> = {
  queryKey: string
  initialParams?: T
  invalidate?: boolean
}

export const useParams = <T extends GetRawMaterialsType>(props: ParamsType<T>) => {
  const { queryKey, initialParams, invalidate } = props

  const [params, handleParams] = React.useState<T>(initialParams ?? ({} as T))

  return [params, setParams] as const

  function setParams(params: T) {
    if (invalidate) {
      queryClient.invalidateQueries({
        predicate: (query) => [queryKey].includes(query.queryKey[0] as string),
        refetchType: 'all',
      })
    }

    handleParams(params)
  }
}
