import moment from 'moment'
import { IconDownload } from '@tabler/icons-react'
import { Button, Divider, Image, Modal, Paper, Table, Text, Title } from '@mantine/core'

import { DATE_FORMAT } from '@constants/app'

import { ModalProps } from '@customTypes/page'
import { GeoCheckpointBaseType } from '@customTypes/geoCheckpoint'

import { useIsMobile, useTableMinWidth } from '@hooks/mobile'

import { getLocationString } from '@utils/geoCheckpoint'

import './preview.scss'
import Map from '@components/shared/map'
import { downloadAttachment } from '@utils/firebase'

const GeoCheckpointPreview = (props: ModalProps<GeoCheckpointBaseType>) => {
  const { open, modal, data = {} as GeoCheckpointBaseType, size, onClose: outerOnClose } = props

  const isMobile = useIsMobile()

  const minWidth = useTableMinWidth(isMobile, true)

  const { attachment, createdAt, message, user, location } = data

  if (!data.uid) return null

  const locationString = getLocationString(data)

  const content = data.uid && (
    <div className="cd-flex cd-flex-col cd-gap-y-[1rem]">
      {!modal && <Title className="cd-text-2xl cd-font-bold">Geo Checkpoint</Title>}

      <Divider />

      <Text className="cd-text-base">{message}</Text>

      <Paper withBorder>
        <Table.ScrollContainer minWidth={minWidth + (isMobile ? 450 : 0)} type="native">
          <Table striped withColumnBorders withRowBorders className="cd-w-full">
            <Table.Thead>
              <Table.Tr>
                <Table.Th>Ubicación</Table.Th>
                <Table.Th>Usuario</Table.Th>
                <Table.Th>Fecha</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              <Table.Tr>
                <Table.Td>{locationString}</Table.Td>
                <Table.Td>{user.displayName ?? user.email}</Table.Td>
                <Table.Td>{moment.unix(createdAt).format(DATE_FORMAT)}</Table.Td>
              </Table.Tr>
            </Table.Tbody>
          </Table>
        </Table.ScrollContainer>
      </Paper>

      <Map location={locationString} marker={[location.latitude, location.longitude]} />

      {Boolean(attachment) && (
        <div>
          <Text className="cd-text-base cd-mb-[0.5rem]">Evidencia</Text>

          <div className="cd-flex cd-flex-col cd-justify-center cd-relative">
            <Image
              alt="Geo Checkpoint"
              className="cd-flex-none cd-object-contain cd-h-[400px] cd-w-auto"
              radius="sm"
              src={attachment}
            />
            <div className="cd-flex cd-justify-center cd-mt-[0.5rem]">
              <Button
                color="green"
                leftSection={<IconDownload size={20} />}
                onClick={() =>
                  downloadAttachment(
                    attachment[0],
                    `GeoCheckpoint_${user.email ?? user.displayName}`,
                  )
                }
              >
                Descargar
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  )

  if (modal) {
    return (
      <Modal
        centered
        fullScreen={isMobile}
        opened={Boolean(open)}
        size={size}
        title={
          modal ? <Title className="cd-text-2xl cd-font-bold">Geo Checkpoint</Title> : undefined
        }
        withCloseButton={modal}
        onClose={() => outerOnClose?.()}
      >
        {content}
      </Modal>
    )
  }

  return content
}

export default GeoCheckpointPreview
