import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { IconInfoCircle, IconMapPinPlus } from '@tabler/icons-react'
import { ActionIcon, Alert, Container, Text } from '@mantine/core'

import { useStoreBase } from '@store/index'

import { ModalFormType } from '@customTypes/page'
import { ShortcutType } from '@customTypes/shortcut'

import { useIsMobile } from '@hooks/mobile'

import FloatActions from '@components/shared/floatActions'
import GeoCheckpointForm from '@components/geoCheckpoint/form'
import { RoutesApp } from '@constants/routes'

import Client from '@pages/private/client'
import Proforma from '@pages/private/proforma'
import GeoCheckpoints from '@pages/private/geoCheckpoint'

const Home = () => {
  const { user } = useStoreBase()

  const isMobile = useIsMobile()

  const navigate = useNavigate()

  const [searchParams] = useSearchParams({
    action: '',
  })

  const [action, setAction] = React.useState<ShortcutType | null>(null)

  const [modalGeoForm, setModalGeoForm] = React.useState<ModalFormType>({
    opened: false,
  })

  React.useEffect(() => {
    const action = searchParams.get('action') as ShortcutType
    if (!action) return

    if (action === 'create-geolocation') {
      setModalGeoForm({ opened: true })
      setAction(action)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.toString()])

  return (
    <React.Fragment>
      <Container
        className="cd-h-full cd-mt-[1rem] cd-mb-[2rem] md:cd-mt-[2rem] md:cd-pb-[4rem]"
        fluid={isMobile}
        size="xl"
      >
        {user?.role === 'admin' && (
          <div className="cd-flex cd-flex-col cd-gap-y-[1rem]">
            <GeoCheckpoints cardPreview />

            <Proforma cardPreview />

            <Client cardPreview />
          </div>
        )}

        {user?.role === 'user' && (
          <div className="cd-flex cd-flex-col cd-gap-y-[1rem] cd-justify-center">
            <Alert
              color="blue"
              icon={<IconInfoCircle />}
              title="Trabajo en progreso"
              variant="light"
            >
              <Text className="cd-text-base">
                Esta sección está en construcción. En un futuro mostrará la lista de pendientes del
                usuario que ha iniciado sesión.
              </Text>
            </Alert>
          </div>
        )}

        <GeoCheckpointForm
          modal
          open={modalGeoForm.opened}
          size="lg"
          onClose={() => {
            setModalGeoForm({ opened: false })
            if (action === 'create-geolocation') {
              navigate(RoutesApp.HOME)
            }
          }}
        />
      </Container>
      <FloatActions>
        <ActionIcon
          color="blue"
          radius="xl"
          size={isMobile ? 54 : 64}
          onClick={() => setModalGeoForm({ opened: true })}
        >
          <IconMapPinPlus size={isMobile ? 24 : 30} />
        </ActionIcon>
      </FloatActions>
    </React.Fragment>
  )
}

export default Home
