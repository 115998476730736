import { useIsMobile } from '@hooks/mobile'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'

type MapProps = {
  className?: string
  marker?: [number, number]
  location: string
}

import './map.scss'
import { Box } from '@mantine/core'

const Map = (props: MapProps) => {
  const { marker, className, location } = props

  const isMobile = useIsMobile()

  return (
    <div className={isMobile ? 'leaflet-container-mobile' : 'leaflet-container-desktop'}>
      <MapContainer center={marker} className={className} scrollWheelZoom={false} zoom={15}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {marker && (
          <Marker position={marker}>
            <Popup>
              <div className="cd-flex cd-flex-col cd-pt-[0.5rem]">
                <span className="cd-text-sm">{location}</span>
                <Box c="dimmed" className="cd-text-sm">
                  Lat: {marker[0].toFixed(3)}, Lng: {marker[1].toFixed(3)}
                </Box>
              </div>
            </Popup>
          </Marker>
        )}
      </MapContainer>
    </div>
  )
}

export default Map
