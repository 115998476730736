import { useIsMobile } from '@hooks/mobile'
import { $ } from '@utils/styles'
import React from 'react'

type FloatActionsProps = {
  children?: React.ReactNode
}

const FloatActions = (props: FloatActionsProps) => {
  const { children } = props

  const isMobile = useIsMobile()

  return (
    <div
      className={$(
        'cd-fixed cd-bottom-0 cd-right-0 cd-flex cd-flex-col cd-gap-y-[1rem]',
        isMobile ? 'cd-mb-[1.25rem] cd-mr-[1rem]' : 'cd-mb-[3rem] cd-mr-[3rem]',
      )}
    >
      {children}
    </div>
  )
}

export default FloatActions
