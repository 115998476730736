import { Helmet } from 'react-helmet'
import { useMantineColorScheme } from '@mantine/core'
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'

import queryClient, { asyncStoragePersister } from '@api/datasource/query'

import { RoutesApp } from '@constants/routes'

//Public
import Root from '@pages/public/root'
import Login from '@pages/auth/login'
import Register from '@pages/auth/register'

//Private
import Home from '@pages/private/home'

import Export from '@pages/private/export'

import RawMaterial from '@pages/private/rawMaterial'
import Warehouse from '@pages/private/warehouse'

import Tool from '@pages/private/tool'
import WarehouseTool from '@pages/private/warehouseTool'

import Receipts from '@pages/private/receipts'

import Block from '@pages/private/block'

import GeoCheckpoint from '@pages/private/geoCheckpoint'

import Client from '@pages/private/client'
import ClientPreview from '@components/client/preview'

import Proformas from '@pages/private/proforma'
import ProformasForm from '@components/proforma/form'
import ProformasPreview from '@components/proforma/preview'

import OrderPurchases from '@pages/private/orderPurchase'
import OrderPurchasesForm from '@components/orderPurchase/form'
import OrderPurchasesPreview from '@components/orderPurchase/preview'

import Drawer from '@components/drawer'
import React from 'react'

const ReactQueryDevtoolsProduction = React.lazy(() =>
  import('@tanstack/react-query-devtools/build/modern/production.js').then((d) => ({
    default: d.ReactQueryDevtools,
  })),
)

const App = () => {
  const { colorScheme } = useMantineColorScheme()

  const [showDevtools, setShowDevtools] = React.useState(false)

  React.useEffect(() => {
    // @ts-expect-error window
    window.toggleDevtools = () => setShowDevtools((old) => !old)
  }, [])

  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{
        persister: asyncStoragePersister,
        //2 days in milliseconds
        maxAge: 2 * 24 * 60 * 60 * 1000,
      }}
    >
      <ReactQueryDevtools initialIsOpen />
      {showDevtools && (
        <React.Suspense fallback={null}>
          <ReactQueryDevtoolsProduction />
        </React.Suspense>
      )}
      <BrowserRouter>
        <Helmet>
          <meta content={colorScheme === 'dark' ? '#242424' : '#ffffff'} name="theme-color" />
        </Helmet>
        <Routes>
          <Route element={<Login />} path={RoutesApp.LOGIN} />
          <Route element={<Register />} path={RoutesApp.REGISTER} />
          <Route element={<Drawer />}>
            <Route element={<Home />} path={RoutesApp.HOME} />
            <Route element={<Export />} path={RoutesApp.EXPORT} />
            <Route element={<Tool />} path={RoutesApp.TOOLS} />
            <Route element={<WarehouseTool />} path={RoutesApp.WAREHOUSE_TOOLS} />
            <Route element={<Receipts />} path={RoutesApp.RECEIPTS} />
            <Route element={<Warehouse />} path={RoutesApp.WAREHOUSE} />
            <Route element={<RawMaterial />} path={RoutesApp.RAW_MATERIALS} />
            <Route element={<Block />} path={RoutesApp.BLOCK} />
            <Route element={<Client />} path={RoutesApp.CLIENTS} />
            <Route element={<ClientPreview />} path={RoutesApp.CLIENT} />
            <Route element={<GeoCheckpoint />} path={RoutesApp.GEO_CHECKPOINTS} />
            <Route element={<Proformas />} path={RoutesApp.PROFORMAS} />
            <Route element={<ProformasForm />} path={RoutesApp.PROFORMA_FORM} />
            <Route element={<ProformasPreview />} path={RoutesApp.PROFORMA_VIEW} />
            <Route element={<OrderPurchases />} path={RoutesApp.ORDER_PURCHASES} />
            <Route element={<OrderPurchasesForm />} path={RoutesApp.ORDER_PURCHASES_FORM} />
            <Route element={<OrderPurchasesPreview />} path={RoutesApp.ORDER_PURCHASES_VIEW} />
          </Route>
          <Route element={<Root />} path="/" />
        </Routes>
      </BrowserRouter>
    </PersistQueryClientProvider>
  )
}

export { App }
