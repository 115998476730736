import { createRoot } from 'react-dom/client'
import { ModalsProvider } from '@mantine/modals'
import { Notifications } from '@mantine/notifications'
import { MantineProvider, createTheme, localStorageColorSchemeManager } from '@mantine/core'

import { App } from '@pages/app'

import '@mantine/core/styles.css'
import '@mantine/dates/styles.css'
import '@mantine/dropzone/styles.css'
import '@mantine/notifications/styles.css'
import '@mantine/carousel/styles.css'
import '@mantine/tiptap/styles.css'
import './index.css'

import 'dayjs/locale/es'

const colorSchemeManager = localStorageColorSchemeManager({
  key: 'mantine-color-scheme',
})

const theme = createTheme({
  primaryColor: 'blue',
  fontSmoothing: true,
  fontFamily: 'Open Sans, sans-serif',
})

const AppWrapper = () => {
  return (
    <MantineProvider
      colorSchemeManager={colorSchemeManager}
      defaultColorScheme="dark"
      theme={theme}
    >
      <ModalsProvider>
        <Notifications autoClose={7500} limit={5} position="top-right" />
        <App />
      </ModalsProvider>
    </MantineProvider>
  )
}

const root = createRoot(document.getElementById('root') as HTMLElement)

root.render(<AppWrapper />)
