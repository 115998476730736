import { $ } from '@utils/styles'

type TextIconProps = {
  className?: string
  leftIcon?: React.ReactNode
  rightIcon?: React.ReactNode
  children: React.ReactNode
  onClick?: () => void
}

const TextIcon = (props: TextIconProps) => {
  const { leftIcon, rightIcon, children, className, onClick } = props
  return (
    <div className={$('cd-flex cd-flex-row cd-gap-[1rem]', className)} onClick={onClick}>
      {leftIcon}
      {children}
      {rightIcon}
    </div>
  )
}

export default TextIcon
