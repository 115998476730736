import React from 'react'
import moment from 'moment'
import { useForm } from '@mantine/form'
import { useListState } from '@mantine/hooks'
import { useNavigate, useParams } from 'react-router-dom'
import {
  IconCalendarPlus,
  IconEdit,
  IconPaperclip,
  IconSearch,
  IconTrash,
  IconX,
} from '@tabler/icons-react'
import { notifications } from '@mantine/notifications'
import { useMutation, useQuery } from '@tanstack/react-query'
import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Container,
  Divider,
  Group,
  Modal,
  Paper,
  Table,
  Text,
} from '@mantine/core'

import DataRepo from '@api/datasource/data'
import queryClient from '@api/datasource/query'

import { ModalFormType, ModalProps } from '@customTypes/page'
import { EventBaseType, SearchEventType } from '@customTypes/event'
import { ClientBaseType } from '@customTypes/client'

import { DATE_FORMAT, UI } from '@constants/app'
import QueryKeys from '@constants/queryKeys'
import { RoutesApp } from '@constants/routes'
import { PriorityClient } from '@constants/client'

import { ErrorService } from '@utils/error'
import { getRemainderText, onFilterEvents } from '@utils/client'
import { invalidateDataQuery, isLoadingMutation, isLoadingOrRefetchQuery } from '@utils/network'

import { useIsMobile, useTableMinWidth } from '@hooks/mobile'

import Input from '@components/shared/input'
import ClientForm from '@components/client/form'
import LoaderText from '@components/shared/loader'
import Breadcrumbs from '@components/shared/breadcrumbs'
import EventForm, { EventFormType } from '@components/event/form'
import ConfirmationModal from '@components/shared/confirmationModal'
import TableCustom, { GenericColumnType } from '@components/shared/table'

import './preview.scss'
import NumberFormat from '@components/shared/Number'
import ImagePreview from '@components/shared/imagePreview'

//import Loader from '@assets/loader.svg'
const INITIAL = {
  field: 'name',
  query: '',
}

const ClientPreview = (props: ModalProps<ClientBaseType>) => {
  const { open, modal, data: dataProp, size = 'lg', onClose: outerOnClose } = props

  const isMobile = useIsMobile()

  const minWidth = useTableMinWidth(isMobile)

  const navigate = useNavigate()

  const { uid } = useParams<{ uid: string }>()

  const [params, setParams] = React.useState<SearchEventType>({})

  const [selectedRows, handlers] = useListState<string>([])

  const [modalDelete, setModalDelete] = React.useState<string | null>(null)

  const [eventModalDelete, setEventModalDelete] = React.useState<EventBaseType[] | null>(null)

  const [modalForm, setModalForm] = React.useState<ModalFormType<ClientBaseType>>({
    opened: false,
  })

  const [modalEventForm, setEventForm] = React.useState<ModalFormType<EventFormType>>({
    opened: false,
  })

  const [modalImagePreview, setModalImagePreview] = React.useState<ModalFormType<string[]>>({
    opened: false,
  })

  const formSearchEvents = useForm<{
    query: string
    field: string
    startDate?: number
    endDate?: number
  }>({
    initialValues: INITIAL,
  })

  const clientQuery = useQuery<ClientBaseType, ErrorService, ClientBaseType>({
    queryKey: [QueryKeys.GET_CLIENT_KEY, uid],
    queryFn: async ({ queryKey }) => {
      const response = await DataRepo.clientEntityService.getClientById(queryKey[1] as string)

      return response
    },
  })

  const data = (clientQuery.data ?? dataProp) as ClientBaseType

  const eventsQuery = useQuery<EventBaseType[], ErrorService, EventBaseType[]>({
    initialData: [],
    enabled: Boolean(uid ?? data.uid),
    queryKey: [QueryKeys.GET_EVENTS_KEY, uid ?? data.uid],
    queryFn: async ({ queryKey }) => {
      const response = await DataRepo.clientEntityService.getEvents({
        clientID: queryKey[1] as string,
      })

      return response
    },
  })

  const clientDeleteMutation = useMutation<void, ErrorService, string>({
    mutationFn: async (uid) => {
      const response = await DataRepo.clientEntityService.deleteClient(uid)

      await queryClient.invalidateQueries({
        predicate: (query) => [QueryKeys.GET_CLIENTS_KEY].includes(query.queryKey[0] as string),
        refetchType: 'all',
      })

      await invalidateDataQuery({
        queryKeys: [QueryKeys.GET_CLIENT_KEY, uid],
        exact: true,
      })

      return response
    },
    onSettled: (_, error) => {
      if (error) {
        return notifications.show({
          color: 'red',
          title: 'Error',
          message: error.message ?? 'Error al eliminar el cliente',
        })
      }

      notifications.show({
        color: 'green',
        title: 'Éxito',
        message: 'Eliminación exitosa',
      })

      navigate(RoutesApp.CLIENTS)
    },
  })

  const deleteEventsMutation = useMutation<void, ErrorService, string[]>({
    mutationFn: async (uids) => {
      const response = await DataRepo.clientEntityService.deleteEvents({
        uids,
        clientID: uid ?? data.uid,
      })

      setParams({})

      await queryClient.invalidateQueries({
        predicate: (query) => [QueryKeys.GET_EVENTS_KEY].includes(query.queryKey[0] as string),
        refetchType: 'all',
      })

      return response
    },
    onSettled: (_, error) => {
      if (error) {
        return notifications.show({
          color: 'red',
          title: 'Error',
          message: error.message ?? 'Error al eliminar el evento',
        })
      }

      notifications.show({
        color: 'green',
        title: 'Éxito',
        message: 'Eliminación exitosa',
      })

      setEventModalDelete(null)
      handlers.setState([])
    },
  })

  const memoFilterClient = React.useMemo(
    () =>
      onFilterEvents({
        data: eventsQuery.data,
        params,
      }),
    [eventsQuery.data, params],
  )

  const isLoading = isLoadingOrRefetchQuery(clientQuery)

  const columns = buildColumns()

  React.useEffect(() => {
    if (!open) {
      handlers.setState([])
    }
    return () => {
      handlers.setState([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const content = (
    <React.Fragment>
      {uid && isLoading && <LoaderText>Cargando información del cliente</LoaderText>}
      {!isLoading && data && (
        <div className="cd-flex cd-flex-col cd-gap-y-[1rem]">
          <div className="cd-flex cd-flex-col md:cd-flex-row md:cd-justify-between cd-gap-[1rem]">
            {!modal && (
              <Breadcrumbs
                className="md:cd-basis-[85%]"
                items={[
                  {
                    text: <Text className="cd-text-lg">Clientes</Text>,
                    href: RoutesApp.CLIENTS,
                  },
                  {
                    text: <Text className="cd-text-lg cd-font-semibold">{data.name}</Text>,
                    href: RoutesApp.CLIENT,
                  },
                ]}
              />
            )}
            <div className="cd-flex cd-flex-row cd-gap-x-[0.5rem] md:cd-basis-[15%]">
              <Button
                className="cd-basis-[80%]"
                color="blue"
                leftSection={<IconEdit size={18} />}
                size={UI.Size}
                variant="filled"
                onClick={() => setModalForm({ opened: true, data })}
              >
                Editar
              </Button>
              <ActionIcon
                className="cd-basis-[20%]"
                color="red"
                size={36}
                variant="light"
                onClick={() => setModalDelete(data.uid)}
              >
                <IconTrash size={18} />
              </ActionIcon>
            </div>
          </div>

          <Divider />

          <div className="cd-flex cd-flex-col md:cd-flex-row md:cd-justify-between cd-gap-[1rem]">
            <Text className="cd-text-base">{data.description}</Text>
            <Badge color={PriorityClient[data.priority].color}>
              {PriorityClient[data.priority].label}
            </Badge>
          </div>

          <Paper withBorder>
            <Table.ScrollContainer minWidth={minWidth + (isMobile ? 450 : 0)} type="native">
              <Table striped withColumnBorders withRowBorders className="cd-w-full">
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th>RUC</Table.Th>
                    <Table.Th>Recordatorio</Table.Th>
                    <Table.Th>Teléfono</Table.Th>
                    <Table.Th>Correo</Table.Th>
                    <Table.Th>Ubicación</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  <Table.Tr>
                    <Table.Td>{data.ruc}</Table.Td>
                    <Table.Td>{getRemainderText(data.remainder)}</Table.Td>
                    <Table.Td>{data.phone}</Table.Td>
                    <Table.Td>{data.email}</Table.Td>
                    <Table.Td>{data.location}</Table.Td>
                  </Table.Tr>
                </Table.Tbody>
              </Table>
            </Table.ScrollContainer>
          </Paper>

          <Divider className="cd-mt-[1rem]" label="Contactos" />

          <Paper withBorder>
            <Table.ScrollContainer minWidth={minWidth + (isMobile ? 450 : 0)} type="native">
              <Table striped withColumnBorders withRowBorders className="cd-w-full">
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th>Nombre</Table.Th>
                    <Table.Th>Correo</Table.Th>
                    <Table.Th>Teléfono</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {data.contacts.map((contact) => (
                    <Table.Tr key={contact.uid}>
                      <Table.Td className={contact.master ? 'cd-font-bold' : ''}>
                        {contact.name} {contact.master && '(Principal)'}
                      </Table.Td>
                      <Table.Td className={contact.master ? 'cd-font-bold' : ''}>
                        {contact.email}
                      </Table.Td>
                      <Table.Td className={contact.master ? 'cd-font-bold' : ''}>
                        {contact.phone}
                      </Table.Td>
                    </Table.Tr>
                  ))}
                  {!data.contacts.length && (
                    <Table.Tr>
                      <Table.Td className="cd-text-center" colSpan={3}>
                        No hay contactos
                      </Table.Td>
                    </Table.Tr>
                  )}
                </Table.Tbody>
              </Table>
            </Table.ScrollContainer>
          </Paper>

          <Divider className="cd-mt-[1rem]" label="Eventos" />

          <div>
            <div className="cd-flex cd-flex-col md:cd-flex-row md:cd-justify-end cd-gap-[1rem] cd-grow cd-mb-[1rem]">
              <div className="md:cd-basis-[80%]" />
              <Button
                fullWidth
                className="md:cd-basis-[20%]"
                color="green"
                leftSection={<IconCalendarPlus size={18} />}
                size={UI.Size}
                variant="filled"
                onClick={() => {
                  if (!uid) return

                  setEventForm({ opened: true, data: { clientID: uid } })
                }}
              >
                Agregar evento
              </Button>
            </div>

            <form
              className="cd-flex cd-flex-col md:cd-flex-row cd-gap-[1rem] cd-grow cd-mb-[1rem]"
              onSubmit={formSearchEvents.onSubmit(({ query, field, endDate, startDate }) =>
                setParams({ query, field, endDate, startDate }),
              )}
            >
              <div className="md:cd-basis-[80%] cd-flex cd-flex-col md:cd-flex-row cd-gap-[1rem] cd-grow">
                <Input
                  allowDeselect={false}
                  className="md:cd-basis-[20%] cd-w-full"
                  data={columns
                    .filter(({ searchable }) => searchable)
                    .map((column) => ({ label: column.label, value: column.key as string }))}
                  placeholder="Campo"
                  typeInput="select"
                  {...formSearchEvents.getInputProps('field')}
                  onChange={(value) => {
                    formSearchEvents.setValues({
                      query: '',
                      field: value ?? '',
                      endDate: undefined,
                      startDate: undefined,
                    })
                  }}
                />
                {formSearchEvents.values.field === 'date' && (
                  <Input
                    className="md:cd-basis-[80%] cd-w-full"
                    placeholder="Escoge un rango de fechas"
                    rightSection={
                      <IconX
                        size={18}
                        onClick={() => {
                          formSearchEvents.reset()
                          setParams(INITIAL)
                        }}
                      />
                    }
                    type="range"
                    typeInput="dateRangePicker"
                    onChange={(value) => {
                      const castValue = value as unknown as [Date, Date]
                      const [startDate, endDate] = castValue

                      formSearchEvents.setFieldValue(
                        'startDate',
                        moment(startDate).startOf('day').unix(),
                      )
                      formSearchEvents.setFieldValue('endDate', moment(endDate).endOf('day').unix())
                    }}
                  />
                )}
                {formSearchEvents.values.field !== 'date' && (
                  <Input
                    className="md:cd-basis-[80%] cd-w-full"
                    placeholder="Buscar evento"
                    rightSection={
                      <IconX
                        size={18}
                        onClick={() => {
                          formSearchEvents.reset()
                          setParams(INITIAL)
                        }}
                      />
                    }
                    typeInput="text"
                    {...formSearchEvents.getInputProps('query')}
                  />
                )}
              </div>
              <Button
                fullWidth
                className="md:cd-basis-[20%]"
                color="blue"
                leftSection={<IconSearch size={18} />}
                size={UI.Size}
                type="submit"
                variant="filled"
                onClick={() => {}}
              >
                Buscar
              </Button>
            </form>

            <TableCustom
              columns={columns}
              data={memoFilterClient}
              extraRows={
                <Table.Tr key="extra">
                  <Table.Td colSpan={4} />
                  <Table.Td className="cd-font-bold">Total gastos</Table.Td>
                  <Table.Td className="cd-font-bold cd-text-right" colSpan={1}>
                    <NumberFormat
                      value={memoFilterClient.reduce((acc, item) => acc + (item.amount ?? 0), 0)}
                    />
                  </Table.Td>
                  <Table.Td colSpan={1} />
                </Table.Tr>
              }
              keyId="uid"
              loading={isLoadingOrRefetchQuery(eventsQuery)}
              loadingMessage="Cargando eventos"
              minWidth={minWidth}
              selectedRows={selectedRows}
              onChangeSelected={handlers}
              onDelete={(ids) => {
                const newEvents = eventsQuery.data.filter((item) => ids.includes(item.uid))

                if (!newEvents?.length) return

                setEventModalDelete(newEvents)
              }}
            />
          </div>

          <EventForm
            modal
            data={modalEventForm.data}
            open={modalEventForm.opened}
            size="lg"
            onClose={() => setEventForm({ opened: false })}
          />

          <ClientForm
            modal
            data={modalForm.data}
            open={modalForm.opened}
            size="xl"
            onClose={() => setModalForm({ opened: false })}
            onSubmitted={() => {}}
          />

          <ImagePreview
            modal
            data={modalImagePreview.data}
            open={modalImagePreview.opened}
            size="xl"
            onClose={() => setModalImagePreview({ opened: false })}
          />

          <ConfirmationModal
            cancelColor="gray"
            cancelText="Cancelar"
            confirmColor="red"
            confirmText="Eliminar"
            loading={isLoadingMutation(clientDeleteMutation)}
            opened={Boolean(modalDelete)}
            title="Eliminar cliente"
            onCancel={() => setModalDelete(null)}
            onConfirm={() => {
              if (!modalDelete) return

              clientDeleteMutation.mutate(modalDelete)
            }}
          >
            <Text className="cd-text-base">
              ¿Estás seguro de eliminar el cliente <strong>{data.name}</strong>?
            </Text>
          </ConfirmationModal>

          <ConfirmationModal
            cancelColor="gray"
            cancelText="Cancelar"
            confirmColor="red"
            confirmText="Eliminar"
            loading={isLoadingMutation(deleteEventsMutation)}
            opened={Boolean(eventModalDelete?.length)}
            title="Eliminar evento"
            onCancel={() => setEventModalDelete(null)}
            onConfirm={() => {
              if (!eventModalDelete && selectedRows.length === 0) return

              const selectedEvents =
                eventModalDelete ??
                eventsQuery.data.filter((item) => selectedRows.includes(item.uid))

              deleteEventsMutation.mutate(selectedEvents.map((item) => item.uid))
            }}
          >
            {eventModalDelete && eventModalDelete.length === 1 && (
              <Text className="cd-text-base">
                ¿Estás seguro de eliminar el evento <strong>{eventModalDelete[0].name}</strong>?
              </Text>
            )}
            {eventModalDelete && eventModalDelete.length > 1 && (
              <Text className="cd-text-base">
                ¿Estás seguro de eliminar los <strong>{eventModalDelete.length} eventos</strong>?
              </Text>
            )}
          </ConfirmationModal>
        </div>
      )}
    </React.Fragment>
  )

  if (modal) {
    return (
      <Modal
        centered
        fullScreen={isMobile}
        opened={Boolean(open)}
        size={size}
        title={modal ? data.name : undefined}
        withCloseButton={modal}
        onClose={() => outerOnClose?.()}
      >
        {content}
      </Modal>
    )
  }

  return (
    <Container className="cd-mt-[1rem] cd-mb-[2rem] md:cd-mt-[2rem] md:cd-pb-[4rem]" size="xl">
      {content}
    </Container>
  )

  function buildColumns() {
    return [
      { key: 'name', label: 'Nombre', width: '15%', type: 'text', searchable: true },
      { key: 'description', label: 'Descripción', width: '35%', type: 'text', searchable: true },
      {
        key: 'attachments',
        label: 'Imágenes',
        width: '10%',
        type: 'calc',
        render: ({ attachments }) =>
          attachments.length ? (
            <Group justify="center">
              <ActionIcon
                variant="transparent"
                onClick={() => {
                  setModalImagePreview({ opened: true, data: attachments })
                }}
              >
                <IconPaperclip size={18} />
              </ActionIcon>
            </Group>
          ) : (
            <Box c="dimmed">No hay imágenes</Box>
          ),
      },
      {
        key: 'date',
        label: 'Fecha',
        width: '15%',
        type: 'calc',
        searchable: true,
        render: ({ date }) => moment.unix(date).format(DATE_FORMAT),
      },
      {
        key: 'amount',
        label: 'Gasto',
        width: '15%',
        type: 'calc',
        align: 'right',
        render: ({ amount }) => (amount ? <NumberFormat value={amount} /> : 'Sin gastos'),
      },
      {
        key: 'actions',
        label: 'Acciones',
        width: '10%',
        type: 'calc',
        render: (item) => (
          <Group justify="center">
            <ActionIcon
              variant="transparent"
              onClick={() => {
                if (!uid) return

                setEventForm({
                  opened: true,
                  data: {
                    clientID: uid,
                    event: item,
                  },
                })
              }}
            >
              <IconEdit size={18} />
            </ActionIcon>
            <ActionIcon
              color="red"
              variant="transparent"
              onClick={() => {
                setEventModalDelete([item])
              }}
            >
              <IconTrash size={18} />
            </ActionIcon>
          </Group>
        ),
      },
    ] as GenericColumnType<EventBaseType>[]
  }
}

export default ClientPreview
