import z from 'zod'

import { ExportEntities } from '@constants/export'

export const ExportEntitySchema = z.custom<keyof typeof ExportEntities>(
  (value) => Object.keys(ExportEntities).includes(value) && value?.length > 0,
  {
    message: 'Entidad inválida',
  },
)

export type ExportEntityType = z.infer<typeof ExportEntitySchema>

export const GetExportEntitySchema = z.object({
  entity: ExportEntitySchema,
  filter: z.object({
    allTimes: z.boolean().optional(),
    startDate: z.number().optional(),
    endDate: z.number().optional(),
  }),
})

export type GetExportEntityType = z.infer<typeof GetExportEntitySchema>

export const GetExportEntitiesBulkSchema = z.array(GetExportEntitySchema)

export type GetExportEntitiesBulkType = z.infer<typeof GetExportEntitiesBulkSchema>

export const ExportEntitiesBulkCreateSchema = z
  .object({
    data: GetExportEntitiesBulkSchema,
  })
  .superRefine(({ data }, ctx) => {
    data.map(({ entity, filter }, idx) => {
      if (!filter.allTimes && (!filter.startDate || !filter.endDate)) {
        ctx.addIssue({
          code: 'custom',
          path: [`data.${idx}.filter`],
          message: 'Debe seleccionar un rango de fechas',
        })
      }
      if (!Object.keys(ExportEntities).includes(entity)) {
        ctx.addIssue({
          code: 'custom',
          path: [`data.${idx}.entity`],
          message: 'Entidad inválida',
        })
      }
    })
  })

export type ExportEntitiesBulkCreateType = z.infer<typeof ExportEntitiesBulkCreateSchema>

export const ExportDataResponseSchema = z.object({
  jobID: z.string(),
  totalItems: z.number(),
})

export type ExportDataResponseType = z.infer<typeof ExportDataResponseSchema>

export const JobSchema = z.object({
  id: z.string(),
  status: z.enum(['pending', 'running', 'completed', 'failed']),
  totalItems: z.number(),
  completedItems: z.number(),
  createdAt: z.number(),
  updatedAt: z.number(),
  url: z.string().optional(),
})

export type JobType = z.infer<typeof JobSchema>
