import z from 'zod'
import { RawMaterialBaseSchema } from './rawMaterial'
import { GetEntitySchema } from './network'
import { DocumentReference } from 'firebase/firestore'

export const OrderPurchaseSchema = z.object({
  uid: z.string(),
  sequential: z.number(),
  name: z.string(),
  description: z.string(),
  updatedAt: z.number(),
  createdAt: z.number(),
  rawMaterials: z.array(
    RawMaterialBaseSchema.extend({
      quantity: z.number().positive(),
      disabled: z.boolean().optional().nullable(),
    }),
  ),
})

export type OrderPurchaseType = z.infer<typeof OrderPurchaseSchema>

export const OrderPurchaseFirebaseSchema = OrderPurchaseSchema.omit({
  rawMaterials: true,
}).extend({
  rawMaterials: z.array(z.custom<DocumentReference>()),
})

export type OrderPurchaseFirebaseType = z.infer<typeof OrderPurchaseFirebaseSchema>

//Create
export const CreateOrderPurchaseSchema = OrderPurchaseSchema.omit({
  uid: true,
  updatedAt: true,
  createdAt: true,
  sequential: true,
}).extend({
  rawMaterials: z.array(RawMaterialBaseSchema.extend({ quantity: z.number().positive() })),
})

export type CreateOrderPurchaseType = z.infer<typeof CreateOrderPurchaseSchema>

//Update
export const UpdateOrderPurchaseSchema = OrderPurchaseSchema.omit({
  updatedAt: true,
  createdAt: true,
  sequential: true,
})

export type UpdateOrderPurchaseType = z.infer<typeof UpdateOrderPurchaseSchema>

//Get orders
export const GetOrderPurchasesSchema = GetEntitySchema.extend({
  startDate: z.number().optional(),
  endDate: z.number().optional(),
})

export type GetOrderPurchasesType = z.infer<typeof GetOrderPurchasesSchema>

//Totals
export const TotalsOrderSchema = z.object({
  total: z.number(),
})

export type TotalsOrderType = z.infer<typeof TotalsOrderSchema>
