import z from 'zod'
import { DocumentReference } from 'firebase/firestore'

import { CommitAction } from '@constants/commits'

import { UserSchema } from './user'
import { GetEntitySchema } from './network'
import { ToolBaseSchema } from './tool'
import { WarehouseBaseSchema } from './warehouse'

export const CommitToolActionSchema = z.custom<keyof typeof CommitAction>(
  (value) => Object.keys(CommitAction).includes(value),
  {
    message: 'Acción inválida',
  },
)

// Used when get all commits
// this will be used always for a specific raw material
export const CommitToolBaseSchema = z.object({
  uid: z.string(),
  message: z
    .string()
    .min(3, 'El mensaje debe tener al menos 3 caracteres')
    .max(150, 'El mensaje debe tener como máximo 150 caracteres')
    .trim(),
  action: CommitToolActionSchema,
  updatedAt: z.number(),
  createdAt: z.number(),
  user: UserSchema,
  attachment: z.string().optional().nullable(),
  tools: z.array(
    z.lazy(() =>
      ToolBaseSchema.omit({
        createdAt: true,
      }).extend({ quantity: z.number() }),
    ),
  ),
})

export type CommitToolBaseType = z.infer<typeof CommitToolBaseSchema>

//Used when get a commit by id (example: update a commit)
// because you need to know the warehouse and raw material of the commit
export const CommitToolSchema = CommitToolBaseSchema.omit({}).extend({
  warehouse: z.lazy(() => WarehouseBaseSchema),
})

export type CommitToolType = z.infer<typeof CommitToolSchema>

// Internal use
export const CommitToolFirebaseSchema = CommitToolBaseSchema.omit({
  user: true,
  tools: true,
}).extend({
  user: z.custom<DocumentReference>(),
  warehouse: z.custom<DocumentReference>(),
  tools: z.array(z.object({ ref: z.custom<DocumentReference>(), quantity: z.number() })),
})

export type CommitToolFirebaseType = z.infer<typeof CommitToolFirebaseSchema>

//
// CRUD OPERATIONS
//
//Create
export const CreateCommitToolSchema = z.object({
  warehouseToolID: z.string(),
  commit: CommitToolBaseSchema.omit({
    uid: true,
    user: true,
    createdAt: true,
    updatedAt: true,
  }),
})

export type CreateCommitToolType = z.infer<typeof CreateCommitToolSchema>

//Update
export const UpdateCommitToolSchema = z.object({
  warehouseToolID: z.string(),
  previous: CommitToolBaseSchema,
  current: CommitToolBaseSchema.omit({
    uid: true,
    user: true,
    createdAt: true,
    updatedAt: true,
  }),
})

export type UpdateCommitToolType = z.infer<typeof UpdateCommitToolSchema>

//
// GET OPERATIONS
//
//Get commits
export const GetCommitToolsSchema = GetEntitySchema.extend({
  warehouseID: z.string(),
  startDate: z.number().optional(),
  endDate: z.number().optional(),
})

export type GetCommitToolsType = z.infer<typeof GetCommitToolsSchema>
