import { SegmentedControl } from '@mantine/core'
import React from 'react'

export type TabType = {
  value: string
  label: string
  content: React.ReactNode
}

type TabsGroupsProps = {
  tabs: TabType[]
  fullWidth?: boolean
  onChange?: (value: string) => void
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
}

const TabsGroups = (props: TabsGroupsProps) => {
  const { tabs, size, fullWidth, onChange } = props

  const [selectedTab, setSelectedTab] = React.useState(tabs[0].value)

  const dataProp = React.useMemo(
    () =>
      tabs.map((tab) => ({
        value: tab.value,
        label: tab.label,
      })),
    [tabs],
  )

  const contentCb = React.useMemo(() => {
    const tab = tabs.find((tab) => tab.value === selectedTab)
    return tab ? tab.content : null
  }, [selectedTab, tabs])

  return (
    <div className="cd-flex cd-flex-col cd-gap-y-[1rem]">
      <SegmentedControl
        data={dataProp}
        fullWidth={fullWidth}
        size={size}
        value={selectedTab}
        onChange={(value) => {
          setSelectedTab(value)
          onChange?.(value)
        }}
      />
      {contentCb}
    </div>
  )
}

export default TabsGroups
