import { Button, Modal } from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'

import { useIsMobile } from '@hooks/mobile'

import { ModalProps } from '@customTypes/page'
import { ForgotPasswordSchema, ForgotPasswordType } from '@customTypes/user'

import Input from '@components/shared/input'
import React from 'react'
import { notifications } from '@mantine/notifications'
import DataRepo from '@api/datasource/data'
import { useMutation } from '@tanstack/react-query'
import { ErrorService } from '@utils/error'
import { isLoadingMutation } from '@utils/network'

const ForgotPassword = (props: ModalProps<string>) => {
  const { open, onClose: outerOnClose, modal, size, data } = props

  const isMobile = useIsMobile()

  const formReset = useForm<ForgotPasswordType>({
    initialValues: {
      email: data || '',
    },
    validate: zodResolver(ForgotPasswordSchema),
  })

  const forgotMutation = useMutation<void, ErrorService, ForgotPasswordType>({
    mutationFn: async ({ email }) => {
      await DataRepo.userService.sendPasswordResetEmail(email)
    },
    onSettled: (_, error) => {
      if (error) {
        return notifications.show({
          color: 'red',
          title: 'Error',
          message: error.message || 'Error desconocido',
        })
      }
      notifications.show({
        color: 'green',
        title: 'Éxito',
        message: 'Se ha enviado un correo para restablecer su contraseña',
      })
      onClose()
    },
  })

  React.useEffect(() => {
    if (open) {
      formReset.setValues({ email: data || '' })
    }

    return () => {
      formReset.reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const isLoading = isLoadingMutation(forgotMutation)

  const content = (
    <form onSubmit={formReset.onSubmit((data) => forgotMutation.mutate(data))}>
      <Input
        required
        label="Email"
        placeholder="Ingrese su correo electrónico"
        type="email"
        typeInput="text"
        {...formReset.getInputProps('email')}
        onBlur={() => formReset.validateField('email')}
      />
      <Button
        fullWidth
        className="cd-mt-[2rem]"
        disabled={isLoading}
        loaderProps={{ type: 'dots' }}
        loading={isLoading}
        type="submit"
      >
        Enviar
      </Button>
    </form>
  )

  if (modal) {
    return (
      <Modal
        centered
        fullScreen={isMobile}
        opened={Boolean(open)}
        size={size}
        title="Recuperar contraseña"
        onClose={() => {
          onClose()
        }}
      >
        {content}
      </Modal>
    )
  }

  return content

  function onClose() {
    outerOnClose?.()
  }
}

export default ForgotPassword
