/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import { useForm } from '@mantine/form'
import { useListState } from '@mantine/hooks'
import { IconSearch } from '@tabler/icons-react'
import { notifications } from '@mantine/notifications'
import { zodResolver } from 'mantine-form-zod-resolver'
import { Button, Fieldset, Modal, Text } from '@mantine/core'
import { useMutation, useQuery } from '@tanstack/react-query'

import {
  WarehouseBaseType,
  CreateWarehouseType,
  UpdateWarehouseType,
  CreateWarehouseSchema,
} from '@customTypes/warehouse'
import { ModalProps } from '@customTypes/page'
import { ReceiptType } from '@customTypes/receipt'
import { RawMaterialBaseType } from '@customTypes/rawMaterial'

import DataRepo from '@api/datasource/data'
import queryClient from '@api/datasource/query'

import QueryKeys from '@constants/queryKeys'

import { useIsMobile, useTableMinWidth } from '@hooks/mobile'

import { ErrorService } from '@utils/error'
import { buildSelectOptions, filterSelect } from '@utils/form'
import { isLoadingMutation, isLoadingOrRefetchQuery } from '@utils/network'

import Input from '@components/shared/input'
import NumberFormat from '@components/shared/Number'
import TableCustom, { GenericColumnType } from '@components/shared/table'
import ConfirmationModal from '@components/shared/confirmationModal'
import { LIMIT_SELECT_SEARCH } from '@constants/app'

const INITIAL: CreateWarehouseType = {
  name: '',
  description: '',
  rawMaterials: [],
  receipts: [],
  inHouse: false,
}

const WarehouseForm = (props: ModalProps<WarehouseBaseType>) => {
  const { open, modal, data, size = 'lg', onSubmitted, onClose: outerOnClose } = props

  const isMobile = useIsMobile()

  const minWidth = useTableMinWidth(isMobile, true)

  const [confirmExit, setConfirmExit] = React.useState(false)

  const [selectedRowsRMs, handlersSelectedRMs] = useListState<string>([])

  const [selectedRowsReceipts, handlersSelectedReceipts] = useListState<string>([])

  const formWarehouse = useForm<CreateWarehouseType>({
    initialValues: INITIAL,
    validate: zodResolver(CreateWarehouseSchema),
  })

  const rawMaterialsQuery = useQuery<RawMaterialBaseType[], ErrorService, RawMaterialBaseType[]>({
    initialData: [],
    queryKey: [QueryKeys.GET_MATERIALS_KEY],
    queryFn: async () => {
      const response = await DataRepo.constructionEntitiesService.getRawMaterials({})

      return response
    },
  })

  const receiptsQuery = useQuery<ReceiptType[], ErrorService, ReceiptType[]>({
    initialData: [],
    queryKey: [QueryKeys.GET_RECEIPTS_EXTENDED_KEY],
    queryFn: async () => {
      const response = await DataRepo.constructionEntitiesService.getReceiptsExtended({})

      return response
    },
  })

  const warehouseCreateMutation = useMutation<WarehouseBaseType, ErrorService, CreateWarehouseType>(
    {
      mutationFn: async (data) => {
        const response = await DataRepo.warehouseEntitiesService.createWarehouse(data)

        await queryClient.invalidateQueries({
          predicate: (query) =>
            [QueryKeys.GET_WAREHOUSES_KEY].includes(query.queryKey[0] as string),
          refetchType: 'all',
        })

        onSubmitted?.(response)

        return response
      },
      onSettled: (_, error) => {
        if (error) {
          return notifications.show({
            color: 'red',
            title: 'Error',
            message: error.message ?? 'Error al crear la bodega',
          })
        }

        notifications.show({
          color: 'green',
          title: 'Éxito',
          message: 'Bodega creada correctamente',
        })

        onClose()
      },
    },
  )

  const warehouseUpdateMutation = useMutation<void, ErrorService, UpdateWarehouseType>({
    mutationFn: async (data) => {
      const response = await DataRepo.warehouseEntitiesService.updateWarehouse(data)

      await queryClient.invalidateQueries({
        queryKey: [QueryKeys.GET_WAREHOUSE_KEY, data.uid],
        refetchType: 'all',
        exact: true,
      })

      onSubmitted?.()

      return response
    },
    onSettled: (_, error) => {
      if (error) {
        return notifications.show({
          color: 'red',
          title: 'Error',
          message: error.message ?? 'Error al actualizar la bodega',
        })
      }

      notifications.show({
        color: 'green',
        title: 'Éxito',
        message: 'Bodega actualizada correctamente',
      })

      onClose?.()
    },
  })

  React.useEffect(() => {
    if (open && data) {
      formWarehouse.setValues(data)
    } else if (!open) {
      formWarehouse.setValues(INITIAL)
      handlersSelectedRMs.setState([])
      handlersSelectedReceipts.setState([])
    }
    formWarehouse.resetDirty()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, data])

  const { rawMaterials, receipts } = formWarehouse.getValues()

  const isLoading = isLoadingMutation(warehouseCreateMutation, warehouseUpdateMutation)

  const content = (
    <form
      onSubmit={formWarehouse.onSubmit((values) => {
        if (data) {
          warehouseUpdateMutation.mutate({
            ...values,
            uid: data.uid,
          } as UpdateWarehouseType)
        } else {
          warehouseCreateMutation.mutate(values as CreateWarehouseType)
        }
      })}
    >
      <div className="cd-flex cd-flex-col cd-gap-y-[1rem]">
        <Input
          label="Nombre"
          placeholder="Nombre"
          typeInput="text"
          {...formWarehouse.getInputProps('name')}
          onBlur={() => formWarehouse.validateField('name')}
        />
        <Input
          autosize
          label="Descripción"
          maxRows={4}
          minRows={2}
          placeholder="Escriba una descripción de la bodega o proyecto"
          typeInput="textarea"
          {...formWarehouse.getInputProps('description')}
          onBlur={() => formWarehouse.validateField('description')}
        />

        <Input
          label="¿Es una bodega interna?"
          labelPosition="left"
          size="lg"
          typeInput="switch"
          {...formWarehouse.getInputProps('inHouse')}
        />

        {data && (
          <Text c="dimmed">
            Para cambiar el stock de una Materia Prima o Receta, haga clic en el botón "Ver
            registros" → "Modificar Stock". Cierre el formulario para ver el botón
          </Text>
        )}

        {!data && (
          <React.Fragment>
            <Fieldset legend="Materias primas">
              <Input
                allowDeselect
                clearable
                searchable
                className="cd-my-[0.5rem]"
                data={buildSelectOptions<RawMaterialBaseType>({
                  data: rawMaterialsQuery.data.filter(
                    (item) => !rawMaterials.some((rm) => rm.uid === item.uid),
                  ),
                  value: 'uid',
                  label: 'name',
                })}
                filter={(input) =>
                  filterSelect({
                    ...input,
                    limit: LIMIT_SELECT_SEARCH,
                  })
                }
                loading={isLoadingOrRefetchQuery(rawMaterialsQuery)}
                nothingFoundMessage={
                  <div>
                    <Text c="dimmed" className="cd-text-sm">
                      {rawMaterialsQuery.data?.length
                        ? 'No se pueden duplicar materias primas, para cambiar stock de clic en el icono de editar en la tabla anterior'
                        : 'No hay materias primas disponibles'}
                    </Text>
                  </div>
                }
                placeholder="Escribe para buscar materia prima existente"
                rightSection={<IconSearch size={18} />}
                typeInput="select"
                value={null}
                onChange={(value) => {
                  const rawMaterial = rawMaterialsQuery.data?.find((item) => item.uid === value)

                  if (!rawMaterial) return

                  formWarehouse.insertListItem('rawMaterials', {
                    ...rawMaterial,
                    stock: 1,
                  } as CreateWarehouseType['rawMaterials'][0])
                }}
              />

              <TableCustom<CreateWarehouseType['rawMaterials'][0]>
                columns={buildColumnsRMs()}
                data={rawMaterials}
                keyId="uid"
                limitPage={5}
                minWidth={minWidth}
                noDataMessage="No se han agregado materias primas"
                selectedRows={selectedRowsRMs}
                onChangeSelected={handlersSelectedRMs}
                onDelete={(ids) => {
                  const filtered = rawMaterials.filter((item) => !ids.includes(item.uid))

                  formWarehouse.setFieldValue('rawMaterials', filtered, { forceUpdate: true })
                  handlersSelectedRMs.setState([])
                }}
              />
            </Fieldset>
            <Fieldset legend="Recetas">
              <Input
                allowDeselect
                clearable
                searchable
                className="cd-my-[0.5rem]"
                data={buildSelectOptions<ReceiptType>({
                  data: receiptsQuery.data.filter(
                    (item) =>
                      !item.rawMaterials.length || !receipts.some((rc) => rc.uid === item.uid),
                  ),
                  value: 'uid',
                  label: ({ identifier, name }) => `${identifier} - ${name}`,
                })}
                filter={(input) =>
                  filterSelect({
                    ...input,
                    limit: LIMIT_SELECT_SEARCH,
                  })
                }
                loading={isLoadingOrRefetchQuery(receiptsQuery)}
                nothingFoundMessage={
                  <div>
                    <Text c="dimmed" className="cd-text-sm">
                      {receiptsQuery.data?.length
                        ? 'No se pueden duplicar recetas, para cambiar stock de clic en el icono de editar en la tabla anterior'
                        : 'No hay recetas disponibles'}
                    </Text>
                  </div>
                }
                placeholder="Escribe para buscar receta existente"
                rightSection={<IconSearch size={18} />}
                typeInput="select"
                value={null}
                onChange={(value) => {
                  const receipt = receiptsQuery.data?.find((item) => item.uid === value)

                  if (!receipt) return

                  if (!receipt.rawMaterials.length) {
                    return notifications.show({
                      color: 'red',
                      title: 'Error',
                      message:
                        'La receta no tiene materias primas asociadas, modifíquela o escoja otra',
                    })
                  }

                  formWarehouse.insertListItem('receipts', {
                    ...receipt,
                    stock: 1,
                  } as CreateWarehouseType['receipts'][0])
                }}
              />

              <TableCustom<CreateWarehouseType['receipts'][0]>
                columns={buildColumnsReceipts()}
                data={receipts}
                keyId="uid"
                limitPage={5}
                minWidth={minWidth}
                noDataMessage="No se han agregado recetas"
                selectedRows={selectedRowsReceipts}
                onChangeSelected={handlersSelectedReceipts}
                onDelete={(ids) => {
                  const filtered = receipts.filter((item) => !ids.includes(item.uid))

                  formWarehouse.setFieldValue('receipts', filtered, { forceUpdate: true })
                  handlersSelectedReceipts.setState([])
                }}
              />
            </Fieldset>
          </React.Fragment>
        )}

        <Button
          className="cd-mt-[1rem]"
          disabled={!formWarehouse.isValid()}
          loaderProps={{ type: 'dots' }}
          loading={isLoading}
          type="submit"
        >
          {data ? 'Guardar bodega' : 'Agregar bodega'}
        </Button>
      </div>
    </form>
  )

  if (modal) {
    return (
      <Modal
        centered
        fullScreen={isMobile}
        opened={Boolean(open)}
        size={size}
        title={data ? 'Editar bodega' : 'Agregar bodega'}
        onClose={() => {
          if (formWarehouse.isDirty()) {
            setConfirmExit(true)
          } else {
            onClose()
          }
        }}
      >
        {content}
        <ConfirmationModal
          cancelColor="gray"
          cancelText="Cancelar"
          confirmColor="red"
          confirmText="Descartar"
          opened={confirmExit}
          title="Descartar cambios"
          onCancel={() => setConfirmExit(false)}
          onConfirm={onClose}
        >
          <Text className="cd-text-base">
            Has realizado cambios en el formulario, ¿Deseas cerrarlo y descartar los cambios?
          </Text>
        </ConfirmationModal>
      </Modal>
    )
  }

  return content

  function buildColumnsReceipts() {
    return [
      { key: 'name', label: 'Nombre', width: '3%', type: 'text' },
      {
        key: 'quantity',
        label: 'Stock',
        width: '5%',
        type: 'number',
        inputProps: {
          min: 1,
          defaultValue: 1,
          allowNegative: false,
          onChange: handleReceiptInput.bind(null, 'stock'),
        },
      },
      {
        key: 'value',
        label: 'Valor ($)',
        width: '3%',
        type: 'calc',
        align: 'right',
        render: ({ rawMaterials }) => (
          <NumberFormat
            value={rawMaterials.reduce((acc, item) => acc + item.value * item.quantity, 0)}
          />
        ),
      },
    ] as GenericColumnType<CreateWarehouseType['receipts'][0]>[]
  }

  function handleReceiptInput(
    key: keyof CreateWarehouseType['receipts'][0],
    value: number | string,
    uid: string,
  ) {
    const receiptIdx = receipts.findIndex((item) => item.uid === uid)

    if (receiptIdx === -1) return

    formWarehouse.setFieldValue(`receipts.${receiptIdx}.${key}`, value, {
      forceUpdate: true,
    })
  }

  function buildColumnsRMs() {
    return [
      { key: 'name', label: 'Nombre', width: '3%', type: 'text' },
      { key: 'measure', label: 'Medida', width: '1%', type: 'text' },
      {
        key: 'quantity',
        label: 'Stock',
        width: '2%',
        type: 'number',
        inputProps: {
          min: 1,
          defaultValue: 1,
          allowNegative: false,
          onChange: handleMaterialInput.bind(null, 'stock'),
        },
      },
      {
        key: 'value',
        label: 'Valor ($)',
        width: '3%',
        type: 'calc',
        align: 'right',
        render: ({ value }) => <NumberFormat value={value} />,
      },
    ] as GenericColumnType<CreateWarehouseType['rawMaterials'][0]>[]
  }

  function handleMaterialInput(
    key: keyof CreateWarehouseType['rawMaterials'][0],
    value: number | string,
    uid: string,
  ) {
    const rawMaterialIdx = rawMaterials.findIndex((item) => item.uid === uid)

    if (rawMaterialIdx === -1) return

    formWarehouse.setFieldValue(`rawMaterials.${rawMaterialIdx}.${key}`, value, {
      forceUpdate: true,
    })
  }

  function onClose() {
    outerOnClose?.()
    setConfirmExit(false)
    formWarehouse.reset()
  }
}

export default WarehouseForm
