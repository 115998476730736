import React, { Suspense } from 'react'
import { notifications } from '@mantine/notifications'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@tanstack/react-query'
import { IconEdit, IconFileTypePdf } from '@tabler/icons-react'
import { Button, Container, Fieldset, Text } from '@mantine/core'

import {
  TotalsOrderType,
  OrderPurchaseType,
  CreateOrderPurchaseType,
} from '@customTypes/orderPurchase'

import DataRepo from '@api/datasource/data'

import QueryKeys from '@constants/queryKeys'
import { RoutesApp } from '@constants/routes'
import { PDF_ORDER_PURCHASE_ID } from '@constants/app'

import { useIsMobile, useTableMinWidth } from '@hooks/mobile'

import { ErrorService } from '@utils/error'
import { generatePDF2 } from '@utils/pdf'
import { getTotalsOrderPurchase } from '@utils/orderPurchase'
import { isLoadingMutation, isLoadingOrRefetchQuery } from '@utils/network'

import Input from '@components/shared/input'
import LoaderText from '@components/shared/loader'
import NumberFormat from '@components/shared/Number'
import TableCustom, { GenericColumnType } from '@components/shared/table'
import { getBase64Image } from '@utils/firebase'

const PDF = React.lazy(() => import('@components/orderPurchase/pdf'))

const OrderPurchasePreview = () => {
  const navigate = useNavigate()

  const { uid } = useParams<{ uid: string }>()

  const isMobile = useIsMobile()

  const minWidth = useTableMinWidth(isMobile)

  const orderPurchaseQuery = useQuery<OrderPurchaseType, ErrorService, OrderPurchaseType, string[]>(
    {
      enabled: !!uid,
      queryKey: [QueryKeys.GET_ORDER_PURCHASE_KEY, String(uid)],
      queryFn: async ({ queryKey }) => {
        const response = await DataRepo.documentEntitiesService.getOrderPurchaseById(queryKey[1])

        return response
      },
    },
  )

  const orderPurchasePDFQuery = useQuery<
    OrderPurchaseType,
    ErrorService,
    OrderPurchaseType,
    [string, OrderPurchaseType | undefined]
  >({
    enabled: !!orderPurchaseQuery.data,
    queryKey: [QueryKeys.GET_ORDER_PDF_KEY, orderPurchaseQuery.data],
    queryFn: async ({ queryKey }) => {
      const [, orderPurchase] = queryKey

      if (!orderPurchase) {
        throw new Error('No se encontró la orden de compra')
      }

      const parseRawMaterials: OrderPurchaseType['rawMaterials'] = []

      for (const rawMaterial of orderPurchase.rawMaterials) {
        const base64Image = await getBase64Image(rawMaterial.attachments[0])

        parseRawMaterials.push({
          ...rawMaterial,
          attachments: [base64Image],
        })
      }

      const newOrderPurchase: OrderPurchaseType = {
        uid: orderPurchase.uid,
        name: orderPurchase.name,
        sequential: orderPurchase.sequential,
        description: orderPurchase.description,
        createdAt: orderPurchase.createdAt,
        updatedAt: orderPurchase.updatedAt,
        rawMaterials: parseRawMaterials,
      }

      console.log('newOrderPurchase', newOrderPurchase)

      return newOrderPurchase
    },
  })

  const totalsOrderPurchaseQuery = useQuery<
    TotalsOrderType,
    ErrorService,
    TotalsOrderType,
    [string, CreateOrderPurchaseType['rawMaterials']]
  >({
    initialData: {
      total: 0,
    },
    refetchOnMount: true,
    queryKey: [QueryKeys.GET_PROFORMA_TOTAL_KEY, orderPurchaseQuery.data?.rawMaterials ?? []],
    queryFn: async ({ queryKey }) => {
      const [, rawMaterials] = queryKey

      return getTotalsOrderPurchase({
        rawMaterials,
      })
    },
  })

  const generatePDFMutation = useMutation<void, ErrorService, string>({
    mutationFn: async (proformaName) => {
      await generatePDF2({
        filename: proformaName,
        elementId: PDF_ORDER_PURCHASE_ID,
      })
    },
    onSettled: (_, error) => {
      if (error) {
        return notifications.show({
          color: 'red',
          title: 'Error',
          message: error.message ?? 'Error al generar el PDF',
        })
      }

      return notifications.show({
        color: 'green',
        title: 'Éxito',
        message: 'PDF generado correctamente',
      })
    },
  })

  const isLoading = isLoadingOrRefetchQuery(orderPurchaseQuery)

  const isGeneratingPDF = isLoadingMutation(generatePDFMutation)

  const isParsingPDF = isLoadingOrRefetchQuery(orderPurchasePDFQuery)

  return (
    <React.Fragment>
      {!isLoading && (
        <React.Fragment>
          <Container
            className="cd-mt-[1rem] cd-mb-[2rem] md:cd-mt-[2rem] md:cd-pb-[4rem]"
            fluid={isMobile}
            size={isMobile ? '100%' : 'xl'}
          >
            <div className="cd-flex cd-justify-end cd-gap-x-[1rem]">
              <Button
                color="gray"
                onClick={() => {
                  navigate(-1)
                }}
              >
                Volver
              </Button>
              <Button
                leftSection={<IconEdit size={20} />}
                onClick={() => {
                  navigate(RoutesApp.PROFORMA_FORM.replace(':uid', String(uid)))
                }}
              >
                Editar
              </Button>
              <Button
                color="green"
                disabled={isParsingPDF}
                leftSection={<IconFileTypePdf size={22} />}
                loading={isGeneratingPDF}
                onClick={() => {
                  generatePDFMutation.mutate(orderPurchaseQuery.data?.name ?? 'orden_compra')
                }}
              >
                Exportar
              </Button>
            </div>
            <div className="cd-flex cd-flex-col cd-gap-y-[0.5rem] cd-mt-[1rem]">
              <Fieldset className="cd-flex cd-flex-col cd-gap-y-[0.5rem]" legend="Orden">
                <Input
                  readOnly
                  className="cd-basis-[100%]"
                  label="Nombre"
                  placeholder="Ingrese el nombre de la orden"
                  typeInput="text"
                  value={orderPurchaseQuery.data?.name}
                  variant="filled"
                />

                <Input
                  autosize
                  readOnly
                  className="cd-basis-[100%]"
                  label="Descripción"
                  maxRows={6}
                  minRows={3}
                  placeholder="Ingrese la descripción de la orden"
                  typeInput="textarea"
                  value={orderPurchaseQuery.data?.description}
                  variant="filled"
                />
              </Fieldset>
              <Fieldset legend="Materiales">
                <TableCustom<OrderPurchaseType['rawMaterials'][0]>
                  alwaysHeader
                  hidePagination
                  columns={buildColumns()}
                  data={orderPurchaseQuery.data?.rawMaterials ?? []}
                  //extraRows={buildExtraRows()}
                  keyId="uid"
                  limitPage={1000}
                  minWidth={minWidth}
                  noDataMessage="Agregue items a la orden de compra"
                />
              </Fieldset>
            </div>
          </Container>
          {orderPurchasePDFQuery.data && totalsOrderPurchaseQuery.data && (
            <Suspense>
              <PDF
                className="cd-fixed cd-opacity-0"
                orderPurchase={orderPurchasePDFQuery.data}
                totals={totalsOrderPurchaseQuery.data}
              />
            </Suspense>
          )}
        </React.Fragment>
      )}
      {isLoading && (
        <LoaderText className="cd-mt-[5rem]">
          <Text c="dimmed" className="cd-text-base">
            Cargando orden de compra
          </Text>
        </LoaderText>
      )}
    </React.Fragment>
  )

  // function buildExtraRows() {
  //   return (
  //     <React.Fragment key="extra-fragment">
  //       <Table.Tr key="total-row">
  //         {/* colSpan={3} */}
  //         <Table.Td colSpan={1} />
  //         <Table.Td align="right" className="cd-font-bold">
  //           Total
  //         </Table.Td>
  //         <Table.Td className="cd-font-bold cd-text-right">
  //           <NumberFormat value={totalsOrderPurchaseQuery.data?.total ?? 0} />
  //         </Table.Td>
  //       </Table.Tr>
  //     </React.Fragment>
  //   )
  // }

  function buildColumns() {
    const columns: GenericColumnType<OrderPurchaseType['rawMaterials'][0]>[] = [
      {
        key: 'name',
        label: 'Nombre',
        width: '35%',
        type: 'calc',
        defaultOnClick: true,
        render: ({ name }) => name,
      },
      {
        key: 'description',
        label: 'Descripción',
        width: '45%',
        type: 'calc',
        hideMenu: true,
        render: ({ description }) => description,
      },
      {
        key: 'quantity',
        label: 'Cantidad',
        width: '20%',
        type: 'calc',
        align: 'right',
        render: ({ quantity }) => <NumberFormat prefix="" value={quantity} />,
      },
      // {
      //   key: 'value',
      //   label: 'P. Unitario',
      //   width: '15%',
      //   type: 'calc',
      //   align: 'right',
      //   render: ({ value }) => <NumberFormat value={value} />,
      // },
      // {
      //   key: 'total',
      //   label: 'Total',
      //   width: '15%',
      //   type: 'calc',
      //   align: 'right',
      //   render: ({ quantity, value }) => <NumberFormat prefix="$" value={quantity * value} />,
      // },
    ]

    return columns
  }
}

export default OrderPurchasePreview
