import z from 'zod'
import { UserSchema } from './user'
import { GetEntitySchema } from './network'
import { DocumentReference } from 'firebase/firestore'

export const GeoPropertiesSchema = z.object({
  name: z.string(),
  country: z.string(),
  state: z.string(),
  city: z.string().optional(),
  street: z.string().optional(),
  suburb: z.string().optional(),
})

export type GeoPropertiesType = z.infer<typeof GeoPropertiesSchema>

export const GeoCheckpointBaseSchema = z.object({
  uid: z.string(),
  user: UserSchema,
  deviceID: z.string(),
  message: z
    .string()
    .min(3, 'El mensaje debe tener al menos 3 caracteres')
    .max(150, 'El mensaje debe tener como máximo 150 caracteres')
    .trim(),
  attachment: z.string().min(1, 'Debe adjuntar una imagen'),
  location: z.object({
    speed: z.number(), // m/s
    altitude: z.number(), // meters
    accuracy: z.number(), // percent
    latitude: z.number(),
    longitude: z.number(),
    properties: GeoPropertiesSchema.optional().nullable(),
  }),
  createdAt: z.number(),
  updatedAt: z.number(),
})

export type GeoCheckpointBaseType = z.infer<typeof GeoCheckpointBaseSchema>

export const GeoCheckpointFirebaseSchema = GeoCheckpointBaseSchema.omit({
  user: true,
}).extend({
  user: z.custom<DocumentReference>(),
})

export type GeoCheckpointFirebaseType = z.infer<typeof GeoCheckpointFirebaseSchema>

//GeoAPIfy
export const GeoAPIfySchema = z.object({
  type: z.string(),
  features: z.array(
    z.object({
      type: z.string(),
      properties: GeoPropertiesSchema,
    }),
  ),
})

export type GeoAPIfyType = z.infer<typeof GeoAPIfySchema>

// Create
export const CreateGeoCheckpointSchema = GeoCheckpointBaseSchema.pick({
  message: true,
  attachment: true,
})

export type CreateGeoCheckpointType = z.infer<typeof CreateGeoCheckpointSchema>

// Update
export const UpdateGeoCheckpointSchema = GeoCheckpointBaseSchema.pick({
  message: true,
  attachment: true,
})

export type UpdateGeoCheckpointType = z.infer<typeof UpdateGeoCheckpointSchema>

// Get
export const GetGeoCheckpointSchema = GetEntitySchema.extend({
  startDate: z.number().optional(),
  endDate: z.number().optional(),
})

export type GetGeoCheckpointType = z.infer<typeof GetGeoCheckpointSchema>
