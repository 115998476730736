import React from 'react'
import moment from 'moment'
import { useMutation } from '@tanstack/react-query'
import { useForm, zodResolver } from '@mantine/form'
import { notifications } from '@mantine/notifications'
import { Button, Modal, Text } from '@mantine/core'

import {
  CreateEventSchema,
  CreateEventType,
  UpdateEventType,
  EventBaseType,
} from '@customTypes/event'

import QueryKeys from '@constants/queryKeys'

import DataRepo from '@api/datasource/data'
import queryClient from '@api/datasource/query'

import { useIsMobile } from '@hooks/mobile'

import { ErrorService } from '@utils/error'
import { isLoadingMutation } from '@utils/network'

import Input from '@components/shared/input'
import { Dropzone } from '@components/shared/dropzone'
import ConfirmationModal from '@components/shared/confirmationModal'
import { ModalProps } from '@customTypes/page'

export type EventFormType = {
  clientID: string | null
  event?: EventBaseType
}

const INITIAL: CreateEventType['data'] = {
  name: '',
  date: moment().unix(),
  description: '',
  amount: undefined,
  attachments: [],
}

const CommitForm = (props: ModalProps<EventFormType>) => {
  const { open, modal, data = {} as EventFormType, size = 'lg', onClose: outerOnClose } = props

  const [confirmExit, setConfirmExit] = React.useState(false)

  const isMobile = useIsMobile()

  const formEvent = useForm<CreateEventType['data']>({
    initialValues: INITIAL,
    validate: zodResolver(CreateEventSchema.shape.data),
  })

  const eventCreateMutation = useMutation<EventBaseType, ErrorService, CreateEventType>({
    mutationFn: async (dataCreate) => {
      const response = await DataRepo.clientEntityService.createEvent(dataCreate)

      await queryClient.invalidateQueries({
        predicate: (query) => [QueryKeys.GET_EVENTS_KEY].includes(query.queryKey[0] as string),
        refetchType: 'all',
      })

      return response
    },
    onSettled: (_, error) => {
      if (error) {
        return notifications.show({
          color: 'red',
          title: 'Error',
          message: error.message ?? 'Error al crear el evento',
        })
      }

      notifications.show({
        color: 'green',
        title: 'Éxito',
        message: 'Evento creado correctamente',
      })

      onClose?.()
    },
  })

  const eventUpdateMutation = useMutation<void, ErrorService, UpdateEventType>({
    mutationFn: async (dataUpdate) => {
      const response = await DataRepo.clientEntityService.updateEvent(dataUpdate)

      await queryClient.invalidateQueries({
        predicate: (query) => [QueryKeys.GET_EVENTS_KEY].includes(query.queryKey[0] as string),
        refetchType: 'all',
      })

      await queryClient.invalidateQueries({
        queryKey: [QueryKeys.GET_EVENT_KEY, dataUpdate.data.uid],
        refetchType: 'all',
        exact: true,
      })

      return response
    },
    onSettled: (_, error) => {
      if (error) {
        return notifications.show({
          color: 'red',
          title: 'Error',
          message: error.message ?? 'Error al actualizar el evento',
        })
      }

      notifications.show({
        color: 'green',
        title: 'Éxito',
        message: 'Evento actualizado correctamente',
      })

      onClose?.()
    },
  })

  React.useEffect(() => {
    if (open && data?.event) {
      formEvent.setValues(data.event)
    } else if (!open) {
      formEvent.setValues(INITIAL)
    }
    formEvent.resetDirty()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.event, open])

  const isLoading = isLoadingMutation(eventCreateMutation, eventUpdateMutation)

  const { event, clientID } = data

  const content = clientID && (
    <form
      className="cd-flex cd-flex-col cd-gap-y-[1rem]"
      onSubmit={formEvent.onSubmit((values) => {
        if (!clientID) return

        if (data.event && event) {
          eventUpdateMutation.mutate({
            clientID,
            data: {
              ...values,
              uid: event.uid,
            },
          })
        } else {
          eventCreateMutation.mutate({
            clientID,
            data: values,
          })
        }
      })}
    >
      <Input
        label="Nombre"
        placeholder="Escriba el nombre del evento"
        typeInput="text"
        {...formEvent.getInputProps('name')}
      />

      <Input
        label="Descripción"
        placeholder="Describa el evento"
        typeInput="text"
        {...formEvent.getInputProps('description')}
      />

      <Input
        label="Gasto"
        placeholder="Ingrese el gasto del evento"
        prefix="$"
        typeInput="number"
        {...formEvent.getInputProps('amount')}
      />

      <Dropzone
        multiple
        disabled={isLoading || formEvent.values.attachments.length === 4}
        files={formEvent.values.attachments ? formEvent.values.attachments : []}
        label="Imágenes"
        maxFiles={4}
        onDrop={(files) => {
          formEvent.setFieldValue('attachments', files)
        }}
      />

      <Button loaderProps={{ type: 'dots' }} loading={isLoading} type="submit">
        {`${event ? 'Guardar' : 'Crear'} evento`}
      </Button>
    </form>
  )

  if (modal) {
    return (
      <Modal
        centered
        fullScreen={isMobile}
        opened={Boolean(open)}
        size={size}
        title={event ? 'Editar evento' : 'Nuevo evento'}
        onClose={() => {
          if (formEvent.isDirty()) {
            setConfirmExit(true)
          } else {
            onClose()
          }
        }}
      >
        {content}
        <ConfirmationModal
          cancelColor="gray"
          cancelText="Cancelar"
          confirmColor="red"
          confirmText="Descartar"
          opened={confirmExit}
          title="Descartar cambios"
          onCancel={() => setConfirmExit(false)}
          onConfirm={onClose}
        >
          <Text className="cd-text-base">
            Has realizado cambios en el formulario, ¿Deseas cerrarlo y descartar los cambios?
          </Text>
        </ConfirmationModal>
      </Modal>
    )
  }

  return content

  function onClose() {
    setConfirmExit(false)
    outerOnClose?.()
  }
}

export default CommitForm
