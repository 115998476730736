import React from 'react'
import { Breadcrumbs as BreadcrumbsMantine, Anchor, Text } from '@mantine/core'

type BreadcrumbsProps = {
  className?: string
  items: { text: string | React.ReactNode; href: string }[]
}

function Breadcrumbs(props: BreadcrumbsProps) {
  const { className, items } = props

  return (
    <BreadcrumbsMantine className={className}>
      {items.map((item, index) => (
        <Anchor c="gray" href={item.href} key={index}>
          {React.isValidElement(item.text) ? (
            item.text
          ) : (
            <Text className="cd-text-base">{item.text}</Text>
          )}
        </Anchor>
      ))}
    </BreadcrumbsMantine>
  )
}

export default Breadcrumbs
