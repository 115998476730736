import React from 'react'
import { useForm, zodResolver } from '@mantine/form'
import { IconArrowRight } from '@tabler/icons-react'
import { notifications } from '@mantine/notifications'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Button, Fieldset, Modal, Text } from '@mantine/core'

import {
  MoveRawMaterialsSchema,
  MoveRawMaterialsType,
  WarehouseBaseType,
  WarehouseType,
} from '@customTypes/warehouse'

import DataRepo from '@api/datasource/data'
import queryClient from '@api/datasource/query'

import QueryKeys from '@constants/queryKeys'

import { useIsMobile, useTableMinWidth } from '@hooks/mobile'

import { ErrorService } from '@utils/error'
import { buildSelectOptions } from '@utils/form'
import { isLoadingMutation } from '@utils/network'

import Input from '@components/shared/input'
import TableCustom, { GenericColumnType } from '@components/shared/table'
import { ModalProps } from '@customTypes/page'

type RMItem = WarehouseType['rawMaterials'][0] & { maxQuantity: number }

type ReceiptItem = WarehouseType['receipts'][0] & { maxQuantity: number }

export type MoveRawMaterialType = {
  warehouseFromID?: string
  rawMaterials?: RMItem[]
  receipts?: ReceiptItem[]
}

const MoveRawMaterials = (props: ModalProps<MoveRawMaterialType>) => {
  const { open, modal, data = {}, size = 'lg', onClose, onSubmitted } = props

  const { rawMaterials, receipts, warehouseFromID } = data

  const isMobile = useIsMobile()

  const minWidth = useTableMinWidth(isMobile, true)

  const warehousesQuery = useQuery<WarehouseBaseType[], ErrorService, WarehouseBaseType[]>({
    queryKey: [QueryKeys.GET_WAREHOUSES_KEY],
    queryFn: async () => {
      const response = await DataRepo.warehouseEntitiesService.getWarehouses({})

      return response
    },
    initialData: [],
  })

  const formMoveItems = useForm<MoveRawMaterialsType>({
    initialValues: {
      warehouseFromID: '',
      warehouseToID: '',
      rawMaterials: [],
      receipts: [],
    },
    validate: zodResolver(MoveRawMaterialsSchema),
  })

  React.useEffect(() => {
    if (open && data) {
      formMoveItems.setValues({
        rawMaterials:
          data.rawMaterials?.map((rawMaterial) => ({
            rawMaterialID: rawMaterial.uid,
            quantity: 1,
          })) ?? [],
        receipts:
          data.receipts?.map((receipt) => ({
            receiptID: receipt.uid,
            quantity: 1,
          })) ?? [],
        warehouseFromID: warehouseFromID ?? '',
        warehouseToID: '',
      })
    } else if (!open) {
      formMoveItems.reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, data.warehouseFromID, data.rawMaterials])

  const moveItemsMutation = useMutation<void, ErrorService, MoveRawMaterialsType>({
    mutationFn: async (data) => {
      await DataRepo.warehouseEntitiesService.moveRawMaterials(data)

      await queryClient.invalidateQueries({
        predicate: (query) => [QueryKeys.GET_COMMITS_KEY].includes(query.queryKey[0] as string),
        refetchType: 'all',
      })

      await queryClient.invalidateQueries({
        queryKey: [QueryKeys.GET_WAREHOUSE_KEY, data.warehouseFromID],
        refetchType: 'all',
        exact: true,
      })
    },
    onSettled: (_, error) => {
      if (error) {
        return notifications.show({
          color: 'red',
          title: 'Error',
          message:
            error.message ??
            `Error al mover los ${rawMaterials?.length ? 'materiales' : 'recetas'}`,
        })
      }

      onClose?.()

      notifications.show({
        color: 'green',
        title: 'Éxito',
        message: `${rawMaterials?.length ? 'Materias primas movidas' : 'Recetas movidas'}  correctamente`,
      })

      onSubmitted?.()
    },
  })

  const content = (rawMaterials || receipts) && warehouseFromID && (
    <form
      className="cd-flex cd-flex-col cd-gap-y-[1rem]"
      onSubmit={formMoveItems.onSubmit((values) => moveItemsMutation.mutate(values))}
    >
      <Fieldset legend="Bodegas">
        <div className="cd-flex md:cd-flex-row cd-grow md:cd-gap-x-[1rem]">
          <Input
            readOnly
            className="cd-grow"
            data={buildSelectOptions({
              data: warehousesQuery.data,
              label: 'name',
              value: 'uid',
            })}
            label="Origen"
            placeholder="Seleccione la bodega de origen"
            typeInput="select"
            {...formMoveItems.getInputProps('warehouseFromID')}
            onBlur={() => formMoveItems.validateField('warehouseFromID')}
          />
          <div className="cd-flex cd-flex-col cd-justify-end">
            <IconArrowRight className="cd-mb-[0.5rem]" size={24} />
          </div>
          <Input
            className="cd-grow"
            data={buildSelectOptions({
              data: warehousesQuery.data.filter((warehouse) => warehouse.uid !== warehouseFromID),
              label: 'name',
              value: 'uid',
            })}
            disabled={warehousesQuery.data.length === 1}
            label="Destino"
            nothingFoundMessage="No hay bodegas disponibles"
            placeholder={
              warehousesQuery.data.length === 1
                ? 'No hay bodegas disponibles'
                : 'Seleccione la bodega de destino'
            }
            typeInput="select"
            {...formMoveItems.getInputProps('warehouseToID')}
            onBlur={() => formMoveItems.validateField('warehouseToID')}
          />
        </div>
      </Fieldset>

      <div className="cd-flex cd-flex-col cd-gap-y-[0.5rem]">
        <Text className="cd-text-sm">
          {Boolean(rawMaterials?.length) && 'Seleccione la cantidad de materias primas a mover'}
          {Boolean(receipts?.length) && 'Seleccione la cantidad de recetas a mover'}
        </Text>
        {Boolean(rawMaterials?.length) && (
          <TableCustom<RMItem>
            columns={buildColumnsRMs()}
            data={rawMaterials}
            keyId="uid"
            limitPage={5}
            minWidth={minWidth}
            noDataMessage="No hay materias primas disponibles"
          />
        )}
        {Boolean(receipts?.length) && (
          <TableCustom<ReceiptItem>
            columns={buildColumnsReceipts()}
            data={receipts}
            keyId="uid"
            limitPage={5}
            minWidth={minWidth}
            noDataMessage="No hay recetas disponibles"
          />
        )}
      </div>

      <Button
        loaderProps={{ type: 'dots' }}
        loading={isLoadingMutation(moveItemsMutation)}
        type="submit"
      >
        Mover {rawMaterials?.length ? 'materias primas' : 'recetas'}
      </Button>
    </form>
  )

  if (modal) {
    return (
      <Modal
        centered
        fullScreen={isMobile}
        opened={Boolean(open)}
        size={size}
        title={`Mover ${rawMaterials?.length ? 'materias primas' : 'recetas'}`}
        onClose={() => onClose?.()}
      >
        {content}
      </Modal>
    )
  }

  return content

  function buildColumnsRMs() {
    return [
      {
        key: 'name',
        label: 'Nombre',
        type: 'text',
        width: '30%',
      },
      {
        key: 'quantity',
        label: 'Cantidad',
        type: 'number',
        width: '15%',
        inputProps: {
          min: 1,
          defaultValue: 1,
          maxKey: 'maxQuantity',
          allowNegative: false,
          onChange: handleMaterialInput.bind(null, 'quantity'),
        },
      },
    ] as GenericColumnType<RMItem>[]
  }

  function buildColumnsReceipts() {
    return [
      {
        key: 'name',
        label: 'Nombre',
        type: 'text',
        width: '30%',
      },
      {
        key: 'quantity',
        label: 'Cantidad',
        type: 'number',
        width: '15%',
        inputProps: {
          min: 1,
          defaultValue: 1,
          maxKey: 'maxQuantity',
          allowNegative: false,
          onChange: handleReceiptInput.bind(null, 'quantity'),
        },
      },
    ] as GenericColumnType<ReceiptItem>[]
  }

  function handleMaterialInput(key: string, value: number | string, uid: string) {
    if (!rawMaterials) return

    const rawMaterialIdx = rawMaterials.findIndex((rawMaterial) => rawMaterial.uid === uid)

    if (rawMaterialIdx === -1) return

    formMoveItems.setFieldValue(`rawMaterials.${rawMaterialIdx}.${key}`, value, {
      forceUpdate: true,
    })
  }

  function handleReceiptInput(key: string, value: number | string, uid: string) {
    if (!receipts) return

    const receiptIdx = receipts.findIndex((receipt) => receipt.uid === uid)

    if (receiptIdx === -1) return

    formMoveItems.setFieldValue(`receipts.${receiptIdx}.${key}`, value, {
      forceUpdate: true,
    })
  }
}

export default MoveRawMaterials
