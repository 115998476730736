import React from 'react'
import { RoutesApp } from '@constants/routes'
import { useStoreBase } from '@store/index'
import { useNavigate } from 'react-router-dom'
import { Center, Text } from '@mantine/core'
import LoaderText from '@components/shared/loader'

const Root = () => {
  const { user } = useStoreBase()

  const navigate = useNavigate()

  React.useEffect(() => {
    if (!user) {
      navigate(RoutesApp.LOGIN)
    } else {
      navigate(RoutesApp.HOME)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Center className="cd-h-full">
      <LoaderText>
        <Text c="dommed" className="cd-text-base">
          Cargando información
        </Text>
      </LoaderText>
    </Center>
  )
}

export default Root
