import { FileWithPath } from '@mantine/dropzone'

export const filesImageToBase64 = async (file: FileWithPath[]): Promise<string[]> => {
  const promises = file.map((f) => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader()

      reader.onload = () => {
        resolve(reader.result as string)
      }

      reader.onerror = reject

      reader.readAsDataURL(f)
    })
  })

  return Promise.all(promises)
}

export const fileImageToBase64 = async (file?: File | null): Promise<string> => {
  if (!file) {
    return ''
  }

  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = () => {
      resolve(reader.result as string)
    }

    reader.onerror = reject

    reader.readAsDataURL(file)
  })
}
