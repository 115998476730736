import { Carousel } from '@mantine/carousel'
import { IconDownload } from '@tabler/icons-react'
import { Button, Image, Modal } from '@mantine/core'

import { ModalProps } from '@customTypes/page'

import { useIsMobile } from '@hooks/mobile'

import { downloadAttachment } from '@utils/firebase'

const ImagePreview = (props: ModalProps<string[]>) => {
  const { open, modal, data = [], size = 'lg', onClose: outerOnClose } = props

  const isMobile = useIsMobile()

  const content =
    data.length === 0 ? (
      <div className="cd-flex cd-flex-col cd-justify-center cd-relative">
        <Image
          alt="Imagen"
          className="cd-flex-none cd-object-contain cd-h-[400px] cd-w-auto"
          radius="sm"
          src={data[0]}
        />
        <div className="cd-flex cd-justify-center cd-mt-[0.5rem]">
          <Button
            color="green"
            leftSection={<IconDownload size={20} />}
            onClick={() => downloadAttachment(data[0], 'imagen')}
          >
            Descargar
          </Button>
        </div>
      </div>
    ) : (
      <Carousel withControls className="control " slideGap="md" style={{ flex: 1 }}>
        {data.map((attachment, index) => (
          <Carousel.Slide className="cd-flex cd-justify-center" key={index}>
            <div className="cd-flex cd-flex-col cd-justify-center cd-relative">
              <Image
                alt={`Imagen ${index + 1}`}
                className="cd-flex-none cd-object-cover cd-h-[400px] cd-w-auto"
                radius="sm"
                src={attachment}
                //onClick={() => setImageFull(attachment)}
              />

              <div className="cd-flex cd-justify-center cd-mt-[0.5rem]">
                <Button
                  color="green"
                  leftSection={<IconDownload size={20} />}
                  onClick={() => downloadAttachment(attachment, `${name}-${index + 1}`)}
                >
                  Descargar
                </Button>
              </div>
            </div>
          </Carousel.Slide>
        ))}
      </Carousel>
    )

  if (modal) {
    return (
      <Modal
        centered
        fullScreen={isMobile}
        opened={Boolean(open)}
        size={size}
        onClose={() => outerOnClose?.()}
      >
        {content}
      </Modal>
    )
  }

  return <div className="cd-my-[1rem]">{content}</div>
}

export default ImagePreview
