import { GeoCheckpointBaseType, GetGeoCheckpointType } from '@customTypes/geoCheckpoint'
import { advancedSearch } from './form'

export const getLocationString = (geo: GeoCheckpointBaseType) => {
  const {
    location: { properties },
  } = geo

  if (!properties) {
    return ''
  }

  const { state, street, city, country, name, suburb } = properties

  return [name, suburb, street, city, state, country].filter((text) => text).join(', ')
}

type SearchParams<T> = {
  params: GetGeoCheckpointType
  data?: T[]
  limit?: number
}

export const onFilterGeoCheckpoint = <T extends GeoCheckpointBaseType>(params: SearchParams<T>) => {
  const {
    params: { field, query },
    data = [],
    limit,
  } = params

  let processedData = data

  if (field === 'date') {
    const { startDate, endDate } = params.params

    if (startDate && endDate) {
      processedData = data.filter(
        (item) => item.createdAt >= startDate && item.createdAt <= endDate,
      )
    } else if (startDate) {
      processedData = data.filter((item) => item.createdAt >= startDate)
    } else if (endDate) {
      processedData = data.filter((item) => item.createdAt <= endDate)
    }
  }

  if (!query || !field || !data) {
    return limit ? data.slice(0, limit) : data
  }

  if (field === 'user') {
    processedData = data.filter((item) =>
      advancedSearch({
        search: query.toLowerCase(),
        label: item.user.displayName.toLowerCase(),
        value: item.user.email.toLowerCase(),
      }),
    )
  } else if (field === 'message') {
    processedData = data.filter((item) =>
      advancedSearch({ search: query.toLowerCase(), label: item.message.toLowerCase() }),
    )
  }

  return limit ? processedData.slice(0, limit) : processedData
}
