import { GetOrderPurchasesType, OrderPurchaseType } from '@customTypes/orderPurchase'
import { advancedSearch } from './form'

type SearchParams<T> = {
  params: GetOrderPurchasesType
  data?: T[]
  limit?: number
}

export const onFilterOrderPurchase = <T extends OrderPurchaseType>(params: SearchParams<T>) => {
  const {
    params: { field, query },
    data = [],
    limit,
  } = params

  let processedData = data

  if (field === 'date') {
    const { startDate, endDate } = params.params

    if (startDate && endDate) {
      processedData = data.filter(
        (item) => item.createdAt >= startDate && item.createdAt <= endDate,
      )
    } else if (startDate) {
      processedData = data.filter((item) => item.createdAt >= startDate)
    } else if (endDate) {
      processedData = data.filter((item) => item.createdAt <= endDate)
    }
  }

  if (!query || !field || !data) {
    return limit ? data.slice(0, limit) : data
  }

  if (field === 'sequential') {
    processedData = data.filter((item) => item.sequential.toString().includes(query))
  } else if (['name', 'description'].includes(field)) {
    const keyField = field as 'name' | 'description'

    processedData = data.filter((item) =>
      advancedSearch({ search: query.toLowerCase(), label: item[keyField].toLowerCase() }),
    )
  }

  return limit ? processedData.slice(0, limit) : processedData
}

type TotalsOrderPurchaseParams = {
  rawMaterials: OrderPurchaseType['rawMaterials']
}

export const getTotalsOrderPurchase = (params: TotalsOrderPurchaseParams) => {
  const { rawMaterials } = params

  const total = rawMaterials.reduce((acc, { quantity, value }) => acc + quantity * value, 0)

  return { total }
}
